import React, { Component } from "react";

import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError, alertSuccess } from '../../../components/alerts';
import Chart from './Chart'
import "./style.css";


class UsersChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersStats: []
    }
  }

  componentDidMount = () => {
    this.fetchUsersStats();
    this.timerInterval = setInterval(() => this.fetchUsersStats(), 60000);
  }

  componentWillUnmount = () => {
    clearInterval(this.timerInterval);
  }

  fetchUsersStats = async () => {
    try {
      const { usersStats } = this.state;
      const { data } = await api.get(`${CONFIG.api_url}/users/stats`);

      if (usersStats.length > 0 && data.stats.length > 0) {
        const prevCount = usersStats[usersStats.length - 1].count;
        const newCount = data.stats[data.stats.length - 1].count;

        if (usersStats.length > data.stats.length || newCount > prevCount) {
          alertSuccess('Congrats! A new user has just registered!');
        }
      }

      this.setState({ usersStats: data.stats });
    } catch (e) {
      alertError('Error while loading stats');
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  render() {
    const { usersStats } = this.state;

    return (
      <Chart chartsData={usersStats} chartTitle="Users registration statistic" />
    );
  }
}

export default UsersChart;

import React, { Component } from "react";
import { Panel, Col, Row, DropdownButton, Glyphicon, FormControl, Label, MenuItem } from "react-bootstrap";
import PropTypes from 'prop-types';
import { fetchUsers } from '../../api/users';
import { alertError } from '../../components/alerts';
import { connect } from '../../store/context';
import "./style.css";


class FilterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleChangeDateLimit = (event) => {
    try {
      const dateLimit = Number(event.target.id) || '';
      const { dispatch, limit, name, role } = this.props;
      fetchUsers(dispatch, 1, limit, name, dateLimit, role);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleChangeRole = (event) => {
    try {
      const role = event.target.id || '';
      const { dispatch, limit, name, dateLimit } = this.props;
      fetchUsers(dispatch, 1, limit, name, dateLimit, role);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleSearchChange = (event) => {
    try {
      const name = event.target.value;
      const { dispatch, limit, dateLimit, role } = this.props;
      fetchUsers(dispatch, 1, limit, name, dateLimit, role);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateStats = () => {
    const { history } = this.props;
    history.push('/users/stats');
  }

  render() {
    const { dateLimit, role, name, count } = this.props;

    return (
      <Panel className="users-filter-panel">
        <Panel.Heading>
          <Row className="show-grid">
            <Col md={3}>
              <Label className="users-filter-label">Period: </Label>
              <DropdownButton title={Number(dateLimit) ? `Last ${dateLimit} days` : 'All Time'} id="date-limit-filter">
                <MenuItem eventKey="1" id="All Time" onClick={this.handleChangeDateLimit}> All Time </MenuItem>
                <MenuItem eventKey="2" id="7" onClick={this.handleChangeDateLimit}> Last 7 days </MenuItem>
                <MenuItem eventKey="3" id="30" onClick={this.handleChangeDateLimit}> Last 14 days </MenuItem>
                <MenuItem eventKey="4" id="30" onClick={this.handleChangeDateLimit}> Last 30 days </MenuItem>
              </DropdownButton>
            </Col>
            <Col md={3}>
              <Label className="users-filter-label">Role: </Label>
              <DropdownButton title={role || 'All'} id="date-limit-filter">
                <MenuItem eventKey="1" onClick={this.handleChangeRole}> All </MenuItem>
                <MenuItem eventKey="2" id="admin" onClick={this.handleChangeRole}> admin </MenuItem>
                <MenuItem eventKey="3" id="consultant" onClick={this.handleChangeRole}> consultant </MenuItem>
                <MenuItem eventKey="4" id="user" onClick={this.handleChangeRole}> user </MenuItem>
              </DropdownButton>
            </Col>
            <Col md={2} className="users-count-block">
              <Label className="users-count-label" onClick={this.handleNavigateStats}>
                Count: {count}
              </Label>
            </Col>
            <Col md={4}>
              <Col xs={10} md={10}>
                <FormControl type="text" placeholder="Search by Name" value={name} onChange={this.handleSearchChange} />
              </Col>
              <Col md={2}>
                <Glyphicon glyph="search" className="filter-search-icon" />
              </Col>
            </Col>
          </Row>
        </Panel.Heading>
      </Panel>
    );
  }
}

const select = (state) => {
  const pages = state.getIn(['users', 'pages'], 0);
  const limit = state.getIn(['users', 'limit'], 0);
  const currentPage = state.getIn(['users', 'currentPage'], '');
  const name = state.getIn(['users', 'name'], '');
  const dateLimit = state.getIn(['users', 'dateLimit'], '');
  const role = state.getIn(['users', 'role'], '');
  const count = state.getIn(['users', 'count'], '');

  return { pages, limit, currentPage, name, dateLimit, role, count };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(FilterPanel);

FilterPanel.propTypes = {
  count: PropTypes.number,
  dateLimit: PropTypes.number,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  limit: PropTypes.number,
  name: PropTypes.string,
  role: PropTypes.string
};

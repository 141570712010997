import React, { Component } from "react";
import { Panel, Row, Col, Label, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { alertError, alertSuccess } from '../../components/alerts';
import CONFIG from '../../config/config';
import api from '../../config/api';
import "./style.css";


class Socials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socialTexts: []
    };
  }

  componentDidMount = async () => {
    try {
      const { data } = await api.get(`${CONFIG.api_url}/social-post-text`);
      this.setState({socialTexts: data.texts});
    } catch (e) {
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  handleEditMessage = (id) => {
    const { history } = this.props;
    history.push(`/socials/edit/${id}`);
  }

  handleDeleteMessage = async (id) => {
    const { history } = this.props;

    try {
      await api.delete(`${CONFIG.api_url}/social-post-text/${id}`);
      history.push(`/socials`);
      alertSuccess('Social Text deleted succesfully!');
    } catch (e) {
      alertError(e.response.data)
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  handleCreateMessage = () => {
    const { history } = this.props;
    history.push('/socials/new');
  }

  getSocialTextsList = () => {
    const { socialTexts } = this.state;
    const list = [];

    if (socialTexts.length > 0) {
      socialTexts.forEach((message) => {
        list.push(
          <Row className="match" key={message.id}>
            <Col md={8}> {message.text} </Col>
            <Col md={2}> {message.Sport.Name.toUpperCase()} </Col>
            <Col md={2} className="buttons-block">
              <Button className="action-btn edit-button" bsStyle="warning" onClick={() => this.handleEditMessage(message.id)}>
                Edit
              </Button>
              <Button className="action-btn edit-button" bsStyle="danger" onClick={() => this.handleDeleteMessage(message.id)}>
                Delete
              </Button>
            </Col>
          </Row>)
      })
    }

    return list;
  }


  render() {
    const socialTextsList = this.getSocialTextsList();

    return (
      <Panel style={{"border": "none"}}>
        <Button className="action-btn edit-button" bsStyle="success" onClick={() => this.handleCreateMessage()}>
          Create Text
        </Button>
        <Panel.Heading>
          <Row>
            <Col md={8}> Text </Col>
            <Col md={2}> Sport Name </Col>
            <Col md={2} />
          </Row>
        </Panel.Heading>
        <Panel.Body style={{"padding": "0 15px"}}>
          {socialTextsList.length > 0 ? socialTextsList : <Label style={{'backgroundColor': 'white', 'color': '#777', 'fontSize': 34, 'padding': '0 0 0 40%', "lineHeight": 2}}>No texts found</Label>}
        </Panel.Body>
      </Panel>
    );
  }
}

export default Socials;

Socials.propTypes = {
  history: PropTypes.object
};

import React, { Component } from "react";
import { Button, Form, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import PropTypes from 'prop-types';
import { v4 as uuid} from 'uuid';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError, alertSuccess } from '../../../components/alerts';
import "./style.css";


class NewMatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      match: {},
      requiredFields: ['MatchID', 'HomeTeam', 'AwayTeam', 'Sport', 'MatchTime', 'HomeROT', 'AwayROT']
    };
  }

  componentDidMount = () => {
    const match = Object.assign({}, this.state.match);
    match.MatchID = uuid();
    this.setState({match})
  }

  getMatchFieldsList = () => {
    const list = [];
    const fields = ['MatchID', 'HomeTeam', 'AwayTeam', 'Sport', 'MatchTime', 'Details', 'DisplayLeague', 'DisplayRegion', 'HomeROT', 'AwayROT', 'HomePitcher', 'AwayPitcher'];
    const { match, requiredFields } = this.state;

    for (const field in fields) {
      let required = '';

      if (requiredFields.includes(fields[field])) {
        required = <span className="red">*</span>
      }

      list.push(
        <FormGroup controlId={fields[field]} key={field}>
          <Col sm={4} componentClass={ControlLabel}> {fields[field]} {required}</Col>
          <Col sm={4}>
            <FormControl type="text" value={match[fields[field]] || ''} onChange={this.handleChangeMatchField} />
          </Col>
        </FormGroup>);
    }

    return list;
  }

  handleChangeMatchField = (event) => {
    const match = Object.assign({}, this.state.match);
    match[event.target.id] = event.target.value;
    this.setState({ match });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { match, requiredFields } = this.state;

      for (const field in requiredFields) {
        if (!match[requiredFields[field]]) {
          return alertError(`${requiredFields[field]} field is required!`);
        }
      }

      const { data } = await api.post(`${CONFIG.api_url}/matches/`, match);

      if (data.success) {
        alertSuccess('Match saved succesfully!');
        const { history } = this.props;
        history.push("/");
      } else if (data.error) {
        alertError(data.error);
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error || e);
    }
  }

  handleCancel = async () => {
    const { history } = this.props;
    history.push('/matches')
  }

  render() {
    const matchFields = this.getMatchFieldsList();
    return (
      <>
        <Form horizontal onSubmit={this.handleSubmit}>
          {matchFields}
          <Col smOffset={4} sm={4}>
            <Button className="save-button" bsStyle="success" bsSize="large" type="submit" > Save </Button>
            <Button bsStyle="danger" bsSize="large" type="submit" onClick={this.handleCancel} > Cancel </Button>
          </Col>
        </Form>
      </>
    );
  }
}

export default NewMatch;

NewMatch.propTypes = {
  history: PropTypes.object
};

import i from 'immutable';
import { combineReducers } from 'redux-immutablejs';
import odds from './odds';
import matches from './matches';
import users from './users';
import results from './results';
import sports from './sports';
import picks from './picks';
import subscriptions from './subscriptions';


export const reducers = combineReducers(i.Map({
  odds,
  matches,
  users,
  results,
  sports,
  picks,
  subscriptions
}));

import { Map, List, fromJS } from 'immutable';
import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE
} from '../actions';


const initialState = Map({
  list: List(),
  pages: 0,
  currentPage: 1,
  limit: 10,
  name: '',
  dateLimit: '',
  role: '',
  loading: false,
  error: null
});

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_BEGIN:
      state = state.set('loading', true);
      break;
    case FETCH_USERS_SUCCESS:
      state = state.set('list', fromJS(action.data.users.users));
      state = state.set('pages', fromJS(action.data.users.pages));
      state = state.set('currentPage', fromJS(action.data.users.currentPage));
      state = state.set('limit', fromJS(action.data.users.limit));
      state = state.set('name', fromJS(action.data.users.name));
      state = state.set('dateLimit', fromJS(action.data.users.dateLimit));
      state = state.set('role', fromJS(action.data.users.role));
      state = state.set('count', fromJS(action.data.users.count));
      state = state.set('loading', false);
      break;
    case FETCH_USERS_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;

    default:
      return state;
  }

  return state;
}

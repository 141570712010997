import React, { Component } from "react";
import PropTypes from 'prop-types';

import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError } from '../../../components/alerts';
import Chart from './Chart'
import "./style.css";


class OddsChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oddsHistory: []
    }
  }

  componentDidMount = async () => {
    try {
      const { id } = this.props.match.params;
      const { data } = await api.get(`${CONFIG.api_url}/odds/history?id=${id}`);
      // const { data } = await api.get(`http://testserver.sportstrader.pro:3000/v1/odds/history?id=0080004d-32ed-4aba-a058-12b4c3cd8934`);
      this.setState({ oddsHistory: data.odds });
    } catch (e) {
      alertError('Error while loading charts');
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  render() {
    const MoneyLineAway = [];
    const MoneyLineHome = [];
    const DrawLine = [];
    const PointSpreadHome = [];
    const PointSpreadAway = [];
    const PointSpreadHomeLine = [];
    const PointSpreadAwayLine = [];

    const TotalNumber = [];
    const OverLine = [];
    const UnderLine = [];
    const { oddsHistory } = this.state;

    if (oddsHistory) {
      oddsHistory.forEach((odd) => {
        const date = new Date(odd.createdAt).getTime();
        MoneyLineAway.push([date, odd['MoneyLineAway']]);
        MoneyLineHome.push([date, odd['MoneyLineHome']]);
        DrawLine.push([date, odd['DrawLine']]);

        if (parseFloat(odd['PointSpreadHome'])) {
          PointSpreadHome.push([date, parseFloat(odd['PointSpreadHome'])]);
        }

        if (parseFloat(odd['PointSpreadAway'])) {
          PointSpreadAway.push([date, parseFloat(odd['PointSpreadAway'])]);
        }

        PointSpreadHomeLine.push([date, odd['PointSpreadHomeLine']]);
        PointSpreadAwayLine.push([date, odd['PointSpreadAwayLine']]);

        TotalNumber.push([date, parseFloat(odd['TotalNumber'])]);
        OverLine.push([date, odd['OverLine']]);
        UnderLine.push([date, odd['UnderLine']]);
      });
    }

    return (
      <>
        <Chart chartsData={{MoneyLineAway, MoneyLineHome}} chartTitle="Money Line" reversed />
        <Chart chartsData={{DrawLine}} chartTitle="Draw Line" reversed />
        <Chart chartsData={{PointSpreadHome, PointSpreadAway}} chartTitle="Point Spread"  />
        <Chart chartsData={{PointSpreadHomeLine, PointSpreadAwayLine}} chartTitle="Point Spread Line" reversed />
        <Chart chartsData={{TotalNumber}} chartTitle="Total Number" />
        <Chart chartsData={{OverLine, UnderLine}} chartTitle="OverLine/UnderLine" reversed />
      </>
    );
  }
}

export default OddsChart;

OddsChart.propTypes = {
  match: PropTypes.object
};

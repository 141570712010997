import React, { Component } from "react";
import { Row, Col, Panel, Button, Label, Pagination } from "react-bootstrap";
import i from 'immutable';
import PropTypes from 'prop-types';
import ResultSearch from './search'
import { alertError } from '../../components/alerts';
import { Loader } from '../../components/loader';
import { fetchPicks, regradePick } from '../../api/picks';
import { getSports } from '../../api/sports';
import { connect } from '../../store/context';
import { alertSuccessWithConfirmaiton } from '../../actions/alerts';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
// import FilterPanel from './FilterPanel';
import "./style.css";


class Picks extends Component {
  constructor(props) {
    super(props);
    const { location: { search } } = this.props;
    const { status, sportId, userEmail, currentPage, limit } = queryString.parse(search);
    this.state = {
      currentPage: currentPage ? currentPage : 1,
      limit: limit ? limit : 10,
      status: status ? status : 'unknown',
      sportId: sportId ? sportId : -1,
      userEmail: userEmail ? userEmail : ''
    };
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    this.getPicks();
    this.timerInterval = setInterval(() => this.getPicks(), 60000);
    getSports(dispatch)
  }

  componentDidUpdate = () => {
    if (this.props.count > 0) {
      // alertError("You have results with unknown status!", true);
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.timerInterval);
  }

  getPicks = async () => {
    try {
      const { currentPage, limit, status, sportId, userEmail } = this.state;
      const { dispatch } = this.props;
      fetchPicks(dispatch, currentPage, limit, status, sportId, userEmail);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }
  handleSearch = async (status, sportId, userEmail) => {
    this.setState({ status: status, sportId: sportId, userEmail: userEmail })
    const { currentPage, limit } = this.state;
    const { history } = this.props;
    history.push(`/picks/?currentPage=${currentPage}&limit=${limit}&status=${status}&sportId=${sportId}&userEmail=${userEmail}`)
    // fetchPicks(dispatch, currentPage, limit, status, sportId, userEmail);
  }

  getPicksList = () => {
    const { picks, location: { search } } = this.props;
    const list = [];

    if (picks.length > 0) {
      picks.forEach((pick) => {
        let matchTime = new Date(pick.Match.MatchTime);
        list.push(
          <Row className="result" key={pick.id}>
            <Col md={1}>
              <Link
                className="send-message"
                title="Match Details"
                to={`/picks/${pick.id}`}
              >
                {pick.id}
              </Link>
            </Col>
            <Col md={2}>
              <Link
                className="send-message"
                title="Match Details"
                to={`/matches/${pick.Match.MatchID}?cancel=/picks${search}`}
              >
                {pick.Match.Details}
              </Link>
            </Col>
            <Col md={1}>
              {matchTime.toUTCString()}
            </Col>
            <Col md={1}>
              <Link
                className="send-message"
                title="Match Result"
                to={`/results/${pick.Match.Result.id}?cancel=/picks${search}`}
              >
                {pick.Match.Result.id}
              </Link>
            </Col>

            <Col md={1}> {pick.Users[0].firstName + (pick.Users[0].lastName ? pick.Users[0].lastName : '')} </Col>
            <Col md={1}> {pick.Status} </Col>
            <Col md={4} className="actions-block">
              <Button className="action-btn edit-button" bsStyle="warning" onClick={() => { this.handleClickRegrade(pick.id) }}>
                Mark for Regrade
              </Button>
            </Col>
          </Row>)
      })
    }

    return list;
  }

  handleClickRegrade = async (id) => {
    const { dispatch } = this.props;
    const result = await alertSuccessWithConfirmaiton(`Regrade Pick`,
      `Please ensure you've confirmed/updated match result before marking it for regrading`);

    if (result.value) {
      await regradePick(dispatch, id)
      const { currentPage, limit, status, sportId, userEmail } = this.state;
      fetchPicks(dispatch, currentPage, limit, status, sportId, userEmail);
    }
  }

  getPagination = () => {
    const { pages, currentPage } = this.props;
    let items = [];
    const delta = 5;
    const left = pages - currentPage < delta ? pages - 10 : currentPage - delta;
    const right = currentPage < delta ? 12 : currentPage + delta + 1;
    pages > 1 ? items.push(<Pagination.First onClick={this.handleNavigateFirst} key="first" />) : items = [];

    for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
      if (pageNumber >= left && pageNumber < right) {
        items.push(<Pagination.Item active={pageNumber === currentPage} onClick={() => this.handleClickPagination(pageNumber)} key={pageNumber}>{pageNumber}</Pagination.Item>);
      }
    }

    pages > 1 ? items.push(<Pagination.Last onClick={this.handleNavigateLast} key="last" />) : items = [];
    return items;
  }

  handleClickPagination = async (newPage) => {
    try {
      const { currentPage, limit, status, sportId, userEmail } = this.state;

      if (currentPage !== newPage) {
        const { dispatch, history } = this.props;
        history.push(`/picks/?currentPage=${newPage}&limit=${limit}&status=${status}&sportId=${sportId}&userEmail=${userEmail}`)
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateFirst = async () => {
    try {
      const { currentPage, limit, status, sportId, userEmail } = this.state;
      const { history } = this.props;
      if (currentPage !== 1) {
        history.push(`/picks/?currentPage=1&limit=${limit}&status=${status}&sportId=${sportId}&userEmail=${userEmail}`)
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateLast = async () => {
    try {
      const { currentPage, limit, status, sportId, userEmail } = this.state;
      const { pages, history } = this.props;

      if (currentPage !== pages) {
        history.push(`/picks/?currentPage=${pages}&limit=${limit}&status=${status}&sportId=${sportId}&userEmail=${userEmail}`)
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  render() {
    const results = this.getPicksList();
    const { loading, Sports } = this.props;
    const { status, userEmail, sportId } = this.state
    const pages = this.getPagination();

    return (
      <>
        <ResultSearch onSearch={this.handleSearch} Sports={Sports} Status={status} UserEmail={userEmail} SportId={Number(sportId)} />
        <Panel className="results-panel-border">
          <Panel.Heading>
            <Row className="panel-title-align">
              <Col md={1}> PickId </Col>
              <Col md={2}> MatchDetails</Col>
              <Col md={1}> MatchTime</Col>
              <Col md={1}> MatchResult</Col>
              <Col md={1}> Owner </Col>
              <Col md={1}> PickStatus </Col>

              <Col md={4} />
            </Row>
          </Panel.Heading>
          <Panel.Body className="results-panel-body">
            {loading && <Loader />}
            {!loading && results.length === 0 && <Label className="no-results-found">No results found</Label>}
            {results.length > 0 && results}
          </Panel.Body>
        </Panel>
        <Pagination bsSize="large">{pages}</Pagination>
      </>
    );
  }
}

const select = (state) => {
  const picks = state.getIn(['picks', 'list'], i.List()).toJS();
  const pages = state.getIn(['picks', 'pages'], 0);
  const limit = state.getIn(['picks', 'limit'], 0);
  const currentPage = state.getIn(['picks', 'currentPage'], '');
  const status = state.getIn(['picks', 'status'], '');
  const count = state.getIn(['picks', 'count'], false);
  const loading = state.getIn(['picks', 'loading'], false);
  const Sports = state.getIn(['sports', 'items'], i.List()).toJS();
  const sportId = state.getIn(['picks', 'sportId'], -1);
  const userEmail = state.getIn(['picks', 'userEmail'], '');

  return { picks, pages, limit, currentPage, status, count, loading, Sports, sportId, userEmail };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Picks);

Picks.propTypes = {
  count: PropTypes.number,
  currentPage: PropTypes.number,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  picks: PropTypes.array,
  location: PropTypes.object
};

import { fetchUsersBegin,
  fetchUsersSuccess,
  fetchUsersError } from '../actions';
import CONFIG from '../config/config';
import api from '../config/api';


export const fetchUsers = async (dispatch, currentPage, limit, name = '', dateLimit = '', role = '') => {
  try {
    dispatch(fetchUsersBegin());
    const { data } = await api.get(`${CONFIG.api_url}/users/all?page=${currentPage}&limit=${limit}&name=${name}&dateLimit=${dateLimit}&role=${role}`);
    data.role = role;
    data.limit = limit;
    data.name = name;
    data.dateLimit = dateLimit;
    dispatch(fetchUsersSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchUsersError(error));
  }
}

import React from 'react';
import ReactHighcharts from 'react-highcharts';
import PropTypes from 'prop-types';


const Chart = (props) => {
  const { chartsData, chartTitle = '', reversed = false } = props;
  const series = [];

  for (const element in chartsData) {
    if (chartsData[element].length > 0) {
      series.push({
        type: 'spline',
        name: element,
        data: chartsData[element]
      })
    }
  }

  const config = {
    chart: {
      height: 300,
      zoomType: 'x'
    },
    xAxis: {
      type: 'datetime'
    },
    yAxis: {
      reversed,
      title: false
    },
    title: {
      text: chartTitle
    },
    series
  };
  return (
    <ReactHighcharts config={config} />
  );
}

export default Chart;

Chart.propTypes = {
  chartTitle: PropTypes.string,
  chartsData: PropTypes.object,
  reversed: PropTypes.bool
};


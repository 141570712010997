import React, { Component } from "react";
import { Row, Col, Panel, Button, Label, Pagination } from "react-bootstrap";
import i from 'immutable';
import PropTypes from 'prop-types';
import ResultSearch from './search'
import { alertError } from '../../components/alerts';
import { Loader } from '../../components/loader';
import { fetchResults } from '../../api/results';
import {getSports} from '../../api/sports';
import { connect } from '../../store/context';
// import FilterPanel from './FilterPanel';
import "./style.css";


class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    this.getResults();
    this.timerInterval = setInterval(() => this.getResults(), 60000);
    getSports(dispatch)
  }

  componentDidUpdate = () => {
    if (this.props.count > 0) {
      // alertError("You have results with unknown status!", true);
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.timerInterval);
  }

  getResults = async () => {
    try {
      const { currentPage, limit, finalType, sportId } = this.state;
      const { dispatch } = this.props;
      fetchResults(dispatch, currentPage, limit, finalType, sportId);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }
  handleSearch = async (finalType, sportId) => {
    this.setState({finalType: finalType, sportId: sportId})
    const { currentPage, limit } = this.state;
    const { dispatch } = this.props;
    fetchResults(dispatch, currentPage, limit, finalType, sportId);
  }

  getResultsList = () => {
    const { results } = this.props;
    const list = [];

    if (results.length > 0) {
      results.forEach((result) => {
        list.push(
          <Row className="result" key={result.id}>
            <Col md={2}> {result.Match && result.Match.HomeTeam} </Col>
            <Col md={2}> {result.Match && result.Match.AwayTeam} </Col>
            <Col md={2}> {result.Match && new Date(result.Match.MatchTime).toUTCString()} </Col>
            <Col md={1}> {result.HomeScore} </Col>
            <Col md={1}> {result.AwayScore} </Col>
            <Col md={1}> {result.BinaryScore} </Col>
            <Col md={1}> {result.FinalType} </Col>
            <Col md={1}> {`${result.Final}`} </Col>
            <Col md={1} className="actions-block">
              <Button className="action-btn edit-button" bsStyle="warning" onClick={() => {this.handleClickEdit(result.id)}}>
                Edit
              </Button>
            </Col>
          </Row>)
      })
    }

    return list;
  }

  handleClickEdit = (id) => {
    const { history } = this.props;
    history.push(`/results/${id}`)
  }

  getPagination = () => {
    const { pages, currentPage } = this.props;
    let items = [];
    const delta = 5;
    const left = pages - currentPage < delta ? pages - 10 : currentPage - delta;
    const right = currentPage < delta ? 12 : currentPage + delta + 1;
    pages > 1 ? items.push(<Pagination.First onClick={this.handleNavigateFirst} key="first" />) : items = [];

    for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
      if (pageNumber >= left && pageNumber < right) {
        items.push(<Pagination.Item active={pageNumber === currentPage} onClick={() => this.handleClickPagination(pageNumber)} key={pageNumber}>{pageNumber}</Pagination.Item>);
      }
    }

    pages > 1 ? items.push(<Pagination.Last onClick={this.handleNavigateLast} key="last" />) : items = [];
    return items;
  }

  handleClickPagination = async (newPage) => {
    try {
      const { currentPage, limit, finalType, sportId } = this.state;

      if (currentPage !== newPage) {
        const { dispatch } = this.props;
        fetchResults(dispatch, newPage, limit, finalType, sportId);
        this.setState({currentPage: newPage})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateFirst = async () => {
    try {
      const { currentPage, limit, finalType, sportId } = this.state;

      if (currentPage !== 1) {
        const { dispatch } = this.props;
        fetchResults(dispatch, 1, limit, finalType, sportId);
        this.setState({currentPage: 1})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateLast = async () => {
    try {
      const { currentPage, limit, finalType, sportId } = this.state;
      const { pages } = this.props;

      if (currentPage !== pages) {
        const { dispatch } = this.props;
        fetchResults(dispatch, pages, limit, finalType, sportId);
        this.setState({currentPage: pages})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  render() {
    const results = this.getResultsList();
    const { loading, Sports } = this.props;
    const pages = this.getPagination();

    return (
      <>
        <ResultSearch onSearch={this.handleSearch} Sports={Sports}/>
        <Panel className="results-panel-border">
          <Panel.Heading>
            <Row className="panel-title-align">
              <Col md={2}> HomeTeam </Col>
              <Col md={2}> AwayTeam </Col>
              <Col md={2}> Match Time </Col>
              <Col md={1}> HomeScore </Col>
              <Col md={1}> AwayScore </Col>
              <Col md={1}> BinaryScore </Col>
              <Col md={1}> FinalType </Col>
              <Col md={1}> Final </Col>
              <Col md={1} />
            </Row>
          </Panel.Heading>
          <Panel.Body className="results-panel-body">
            {loading && <Loader />}
            {!loading && results.length === 0 && <Label className="no-results-found">No results found</Label>}
            {results.length > 0 && results}
          </Panel.Body>
        </Panel>
        <Pagination bsSize="large">{pages}</Pagination>
      </>
    );
  }
}

const select = (state) => {
  const results = state.getIn(['results', 'list'], i.List()).toJS();
  const pages = state.getIn(['results', 'pages'], 0);
  const limit = state.getIn(['results', 'limit'], 0);
  const finalType = state.getIn(['results', 'finalType'], 'unknown');
  const currentPage = state.getIn(['results', 'currentPage'], '');
  const type = state.getIn(['results', 'type'], '');
  const count = state.getIn(['results', 'count'], false);
  const loading = state.getIn(['results', 'loading'], false);
  const Sports = state.getIn(['sports', 'items'], i.List()).toJS();
  const sportId = state.getIn(['results', 'sportId'], -1);

  return { results, pages, limit, currentPage, type, count, loading, finalType, Sports, sportId };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Results);

Results.propTypes = {
  count: PropTypes.number,
  currentPage: PropTypes.number,
  finalType: PropTypes.string,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  results: PropTypes.array
};

import { Map, List, fromJS } from 'immutable';
import {
  FETCH_PICKS_BEGIN,
  FETCH_PICKS_SUCCESS,
  FETCH_PICKS_FAILURE
} from '../actions';


const initialState = Map({
  list: List(),
  pages: 0,
  currentPage: 1,
  limit: 10,
  type: '',
  count: 0,
  loading: false,
  error: null
});

export default function picksReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PICKS_BEGIN:
      state = state.set('loading', true);
      break;
    case FETCH_PICKS_SUCCESS:
      console.log(action.data.picks)
      state = state.set('list', fromJS(action.data.picks.picks));
      state = state.set('pages', fromJS(action.data.picks.pages));
      state = state.set('currentPage', fromJS(action.data.picks.currentPage));
      state = state.set('limit', fromJS(action.data.picks.limit));
      state = state.set('status', fromJS(action.data.picks.status));
      state = state.set('count', fromJS(action.data.picks.count));
      state = state.set('loading', false);
      break;
    case FETCH_PICKS_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;

    default:
      return state;
  }

  return state;
}

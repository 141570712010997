import React, { Component } from "react";
import { Button, FormGroup, FormControl, ControlLabel, Label } from "react-bootstrap";
import "./style.css";
import CONFIG from '../../config/config';
import api from '../../config/api';
import { alertError } from '../../components/alerts';


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (this.state.email.length === 0 || this.state.password.length === 0) {
        return alertError("Please enter your email and password!");
      }

      const { email, password } = this.state;
      const { data } = await api.post(`${CONFIG.api_url}/users/login`, { email, password });
      const { role } = data.user;
      const { history } = this.props;

      if (role === 'admin') {
        localStorage.setItem("token", JSON.stringify(data.token));
        history.push('/');
      } else {
        return alertError("Access denied!");
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error);
    }
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <Label className="login-panel-title">Admin Panel</Label>
          <FormGroup controlId="email" bsSize="large" className="login-form-group">
            <ControlLabel>Email</ControlLabel>
            <FormControl autoFocus type="email" value={this.state.email} onChange={this.handleChange} />
          </FormGroup>

          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl value={this.state.password} onChange={this.handleChange} type="password" />
          </FormGroup>

          <Button block bsSize="large" type="submit" > Login </Button>
        </form>
      </div>
    );
  }
}

export default Login;

import React, { Component } from "react";
import { Panel, Row, Col, Label, Button, DropdownButton, MenuItem } from 'react-bootstrap';
import { alertError, alertSuccess } from '../../components/alerts';
import { ModalDialog } from '../../components/modal';
// import { Loader } from '../../components/loader';
import CONFIG from '../../config/config';
import api from '../../config/api';
import "./style.css";


class Payments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payments: [],
      withdrawFilter: 'all',
      showModal: false,
      selectedId: null
    };
  }

  componentDidMount = () => {
    this.fetchPayments();
  }

  fetchPayments = async () => {
    try {
      const { withdrawFilter } = this.state;
      const { data: { payments } } = await api.get(`${CONFIG.api_url}/payments/withdraw?state=${withdrawFilter}`);
      const { data: { income } } = await api.get(`${CONFIG.api_url}/payments/income`);
      this.setState({ payments, totalIncome: income });
    } catch (e) {
      alertError('Error while loading payments');
    }
  }

  getWithdrawList = () => {
    const { payments, withdrawFilter } = this.state;
    const list = [];

    if (payments.length > 0) {
      payments.forEach((payment) => {
        list.push(
          <Row className="match" key={payment.id}>
            <Col md={3}> {payment.description + '(' + payment.itemType + ')'} </Col>
            <Col md={3}> {(payment.User) ? payment.User.email : ''} </Col>
            <Col md={2}> {payment.price} </Col>
            <Col md={2}> {payment.state} </Col>
            <Col md={2}> {payment.status} </Col>
            <Col md={2} />
            <Col md={1} className="complete-block">
              {withdrawFilter === 'pending' ?
                <Button className="action-btn complete-button" bsStyle="success" onClick={() => { this.handleShowModal(payment.id) }}>
                  Complete
                </Button>
                : ''}
            </Col>
          </Row>)
      })
    }

    return list;
  }

  handleChangeFilter = async (event) => {
    await this.setState({ withdrawFilter: event.target.id });
    this.fetchPayments();
  }

  handleShowModal = (itemId) => {
    this.setState({ showModal: true, selectedId: itemId })
  }

  handleHideModal = () => {
    this.setState({ showModal: false, selectedId: null })
  }

  handleCompleteWithdraw = async () => {
    try {
      const { selectedId } = this.state;
      const { data } = await api.patch(`${CONFIG.api_url}/payments/withdraw/complete/${selectedId}`);

      if (data) {
        alertSuccess(data.message);
        this.handleHideModal();
      }

      this.fetchPayments();
    } catch (e) {
      alertError('Error while compliting withdrawal');
    }
  }

  render() {
    const withdrawList = this.getWithdrawList();
    const { withdrawFilter, showModal, totalIncome } = this.state;

    return (
      <>
        <Panel className="payments-panel">
          <Panel.Heading>
            <Row className="show-grid">
              <Col md={3}>
                <Label className="payments-panel-label"> Filter: </Label>
                <DropdownButton title={withdrawFilter || 'pending'} id="sports-filter">
                  <MenuItem eventKey="1" id="pending" onClick={this.handleChangeFilter} active={!withdrawFilter || withdrawFilter === "pending"}> pending </MenuItem>
                  <MenuItem eventKey="2" id="completed" onClick={this.handleChangeFilter} active={withdrawFilter === "completed"}> completed </MenuItem>
                  <MenuItem eventKey="3" id="all" onClick={this.handleChangeFilter} active={withdrawFilter === "all"}> all </MenuItem>
                </DropdownButton>
              </Col>
              <Col md={6} />
              <Col md={3} className="income-block">
                <Label className="income-label">
                  Total Income: {Math.round(totalIncome * 100) / 100}
                </Label>
              </Col>
            </Row>
          </Panel.Heading>
        </Panel>
        <Panel className="payments-table-header">
          <Panel.Heading>
            <Row className="panel-title-align">
              <Col md={3}> Type </Col>
              <Col md={3}> PayPal Email </Col>
              <Col md={2}> sum </Col>
              <Col md={2}> state </Col>
              <Col md={2}> status </Col>
              <Col md={2} />
              <Col md={1} />
            </Row>
          </Panel.Heading>
          <Panel.Body className="payments-table-content">
            {/* {loading  ? <Loader /> : ''} */}
            {withdrawList.length > 0 ? withdrawList : <Label className="no-payments-found">No withdraw requests found</Label>}
          </Panel.Body>
        </Panel>
        {showModal ? <ModalDialog onCancel={this.handleHideModal} onConfirm={this.handleCompleteWithdraw} /> : ''}
      </>
    );
  }
}

export default Payments;

import React from 'react';
import ReactHighcharts from 'react-highcharts';
import PropTypes from 'prop-types';


const Chart = (props) => {
  const { chartsData, chartTitle = '' } = props;
  const series = [];
  const data = [];

  if (chartsData && chartsData.length > 0) {
    chartsData.forEach((day) => {
      const timestamp = new Date(day.date).getTime();
      let value;

      if (day.count) {
        value = data[data.length - 1] ? data[data.length - 1][1] + day.count : day.count;
      }

      data.push([timestamp, value]);
    })

    series.push({
      type: 'spline',
      name: `Users (${data[data.length - 1][1]})`,
      marker: { enabled: true },
      data
    })
  }

  const config = {
    chart: {
      height: 300,
      zoomType: 'x'
    },
    xAxis: {
      type: 'datetime'
    },
    yAxis: {
      title: false
    },
    title: {
      text: chartTitle
    },
    series
  };
  return (
    <ReactHighcharts config={config} />
  );
}

export default Chart;

Chart.propTypes = {
  chartTitle: PropTypes.string,
  chartsData: PropTypes.object
};


import React, { Component } from "react";
import { Row, Col, Pagination, Panel, Label } from "react-bootstrap";
import i from 'immutable';
import PropTypes from 'prop-types';

import { alertError } from '../../components/alerts';
import { Loader } from '../../components/loader';
import { fetchUsers } from '../../api/users';
import { connect } from '../../store/context';
import FilterPanel from './FilterPanel';
import "./style.css";


class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { dispatch } = this.props;
      fetchUsers(dispatch, currentPage, limit);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  getUsersList = () => {
    const { users } = this.props;
    const result = [];

    if (users.length > 0) {
      users.forEach((user) => {
        result.push(
          <Row className="match" key={user.id}>
            <Col md={3}> {user.firstName} </Col>
            <Col md={3}> {user.lastName} </Col>
            <Col md={3}> {user.email} </Col>
            <Col md={2}> {user.role} </Col>
            <Col md={1} />
          </Row>)
      })
    }

    return result;
  }

  handleClickPagination = async (newPage) => {
    try {
      const { currentPage, limit } = this.state;

      if (currentPage !== newPage) {
        const { dispatch, name, dateLimit, role } = this.props;
        fetchUsers(dispatch, newPage, limit, name, dateLimit, role);
        this.setState({currentPage: newPage})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateFirst = async () => {
    try {
      const { currentPage, limit } = this.state;

      if (currentPage !== 1) {
        const { dispatch, name, dateLimit, role } = this.props;
        fetchUsers(dispatch, 1, limit, name, dateLimit, role);
        this.setState({currentPage: 1})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateLast = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { pages } = this.props;

      if (currentPage !== pages) {
        const { dispatch, name, dateLimit, role } = this.props;
        fetchUsers(dispatch, pages, limit, name, dateLimit, role);
        this.setState({currentPage: pages})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  getPagination = () => {
    const { pages, currentPage } = this.props;
    let items = [];
    const delta = 5;
    const left = pages - currentPage < delta ? pages - 10 : currentPage - delta;
    const right = currentPage < delta ? 12 : currentPage + delta + 1;
    pages > 1 ? items.push(<Pagination.First onClick={this.handleNavigateFirst} key="first" />) : items = [];

    for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
      if (pageNumber >= left && pageNumber < right) {
        items.push(<Pagination.Item active={pageNumber === currentPage} onClick={() => this.handleClickPagination(pageNumber)} key={pageNumber}>{pageNumber}</Pagination.Item>);
      }
    }

    pages > 1 ? items.push(<Pagination.Last onClick={this.handleNavigateLast} key="last" />) : items = [];
    return items;
  }

  render() {
    const { loading } = this.props;
    const users = this.getUsersList();
    const pages = this.getPagination();

    return (
      <>
        <FilterPanel history={this.props.history} />
        <Panel className="users-panel-border">
          <Panel.Heading>
            <Row className="panel-title-align">
              <Col md={3}> FirstName </Col>
              <Col md={3}> LastName </Col>
              <Col md={3}> Email </Col>
              <Col md={2}> Role </Col>
              <Col md={1} />
            </Row>
          </Panel.Heading>
          <Panel.Body className="users-panel-body">
            {loading && <Loader />}
            {!loading && users.length === 0 && <Label className="no-results-found">No users found</Label>}
            {users.length > 0 && users}
          </Panel.Body>
        </Panel>
        <Pagination bsSize="large">{pages}</Pagination>
      </>
    );
  }
}

const select = (state) => {
  const users = state.getIn(['users', 'list'], i.List()).toJS();
  const pages = state.getIn(['users', 'pages'], 0);
  const limit = state.getIn(['users', 'limit'], 0);
  const currentPage = state.getIn(['users', 'currentPage'], '');
  const name = state.getIn(['users', 'name'], '');
  const dateLimit = state.getIn(['users', 'dateLimit'], '');
  const role = state.getIn(['users', 'role'], '');
  const loading = state.getIn(['users', 'loading'], false);

  return { users, pages, limit, currentPage, name, dateLimit, role, loading };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Users);

Users.propTypes = {
  currentPage: PropTypes.number,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  users: PropTypes.array
};

import { Map, List, fromJS } from 'immutable';
import {
  FETCH_SPORTS_BEGIN,
  FETCH_SPORTS_SUCCESS,
  FETCH_SPORTS_FAILURE
} from '../actions';


const initialState = Map({
  items: List(),
  selectedSport: Map(),
  loading: false,
  error: null
});

export default function sportsReducer(state = initialState, action) {
  switch (action.type) {
    
    case FETCH_SPORTS_BEGIN:
      state = state.set('loading', true);
      break;

    case FETCH_SPORTS_SUCCESS:
      state = state.set('items', fromJS(action.data.sports.sports));
      state = state.set('loading', false);
      break;

    case FETCH_SPORTS_FAILURE:
      state = state.set('error', action.error);
      break;

    default:
      return state;
  }

  return state;
}

import { Map, List, fromJS } from 'immutable';
import {
  FETCH_SUBSCRIPTIONS_SUCCESS,
} from '../actions';


const initialState = Map({
  list: List(),
  pages: 0,
  currentPage: 1,
  limit: 10,
  searchValue: '',
  sportId: '',
  loading: false,
  error: null
});

export default function matchesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      state = state.set('list', fromJS(action.data.subscriptions.subscriptions));
      state = state.set('pages', fromJS(action.data.subscriptions.pages));
      state = state.set('currentPage', fromJS(action.data.subscriptions.currentPage));
      state = state.set('limit', fromJS(action.data.subscriptions.limit));
      state = state.set('searchValue', fromJS(action.data.subscriptions.searchValue));
      state = state.set('sportId', fromJS(action.data.subscriptions.sportId));
      state = state.set('matchTime', fromJS(action.data.subscriptions.matchTime));
      state = state.set('loading', false);
      break;
    default:
      return state;
  }

  return state;
}

import { Map, List, fromJS } from 'immutable';
import {
  FETCH_RESULTS_BEGIN,
  FETCH_RESULTS_SUCCESS,
  FETCH_RESULTS_FAILURE
} from '../actions';


const initialState = Map({
  list: List(),
  pages: 0,
  currentPage: 1,
  limit: 10,
  type: '',
  count: 0,
  loading: false,
  error: null
});

export default function resultsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESULTS_BEGIN:
      state = state.set('loading', true);
      break;
    case FETCH_RESULTS_SUCCESS:
      state = state.set('list', fromJS(action.data.results.results));
      state = state.set('pages', fromJS(action.data.results.pages));
      state = state.set('currentPage', fromJS(action.data.results.currentPage));
      state = state.set('limit', fromJS(action.data.results.limit));
      state = state.set('type', fromJS(action.data.results.type));
      state = state.set('count', fromJS(action.data.results.count));
      state = state.set('loading', false);
      break;
    case FETCH_RESULTS_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;

    default:
      return state;
  }

  return state;
}

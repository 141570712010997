import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel, Col, Button, Label } from "react-bootstrap";
import "./style.css";
import PropTypes from 'prop-types';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError, alertSuccess } from '../../../components/alerts';


class EditOdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      odd: this.props.odd,
      updatedFields: {}
    };
  }

  componentDidMount = async () => {
    try {
      const { id } = this.props.match.params;
      const { data } = await api.get(`${CONFIG.api_url}/odds/${id}`);
      this.setState({odd: data.odd});
    } catch (e) {
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { updatedFields } = this.state;
      const { id } = this.props.match.params;
      const { data } = await api.patch(`${CONFIG.api_url}/odds/${id}`, updatedFields);

      if (data.message) {
        alertSuccess(data.message);
        const { history } = this.props;
        history.push("/odds");
      } else if (data.error) {
        alertError(data.error);
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleChangeOdd = (event) => {
    const odd = Object.assign({}, this.state.odd);
    odd[event.target.id] = event.target.value;
    this.setState({ odd });
    const changes = Object.assign({}, this.state.updatedFields);

    if (event.target.value === '') {
      changes[event.target.id] = null;
    } else {
      changes[event.target.id] = event.target.value;
    }

    this.setState({ updatedFields: changes });
  }

  handleChangeMatch = (event) => {
    const odd = Object.assign({}, this.state.odd);
    odd.Match[event.target.id] = event.target.value;
    this.setState({ odd });

    const changes = Object.assign({}, this.state.updatedFields);

    if (!changes.Match) {
      changes.Match = {};
    }

    if (event.target.value === '') {
      changes.Match[event.target.id] = null;
    } else {
      changes.Match[event.target.id] = event.target.value;
    }

    this.setState({ updatedFields: changes });
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push("/odds");
  }

  getOddsFieldsList = () => {
    const { odd } = this.state;
    const list = [];
    const hidenFields = ['id', 'createdAt', 'updatedAt', 'LastUpdated', 'Match', 'EventID', 'editedBy'];

    if (odd) {
      for (const field in odd) {
        if (odd.hasOwnProperty(field) && hidenFields.indexOf(field) < 0) {
          list.push(
            <FormGroup controlId={field} key={field}>
              <Col sm={6}>
                {field}
              </Col>
              <Col sm={6}>
                <FormControl type="text" value={odd[field] || ''} placeholder={field} onChange={this.handleChangeOdd} />
              </Col>
            </FormGroup>);
        }
      }
    }

    return list;
  }

  getMatchFieldsList = () => {
    const { odd } = this.state;
    const list = [];
    const hidenFields = ['id', 'createdAt', 'updatedAt', 'MatchID'];

    if (odd && odd.Match) {
      for (const field in odd.Match) {
        if (odd.Match.hasOwnProperty(field) && hidenFields.indexOf(field) < 0) {
          list.push(
            <FormGroup controlId={field} key={field}>
              <Col sm={6} componentClass={ControlLabel}>
                {field}
              </Col>
              <Col sm={6}>
                <FormControl type="text" value={odd.Match[field] || ''} placeholder={field} onChange={this.handleChangeMatch} />
              </Col>
            </FormGroup>);
        }
      }
    }

    return list;
  }

  render() {
    const oddsFields = this.getOddsFieldsList();
    const matchFields = this.getMatchFieldsList();

    return (
      <>
        <Col sm={6}>
          <h2 className="edit-page-title">
            <Label className="edit-page-label">Odds info</Label>
          </h2>
          <Form horizontal>
            {oddsFields}
          </Form>
        </Col>
        <Col sm={6}>
          <h2 className="edit-page-title">
            <Label className="edit-page-label">Match info</Label>
          </h2>
          <Form horizontal onSubmit={this.handleSubmit}>
            {matchFields}
            <Col smOffset={5} sm={10}>
              <Button className="save-button" bsStyle="success" bsSize="large" type="submit" > Save </Button>
              <Button bsStyle="danger" bsSize="large" type="submit" onClick={this.handleCancel} > Cancel </Button>
            </Col>
          </Form>
        </Col>
      </>
    );
  }
}

export default EditOdd;

EditOdd.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  odd: PropTypes.object
};

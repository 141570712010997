import React, { Component } from 'react';
import { Redirect } from 'react-router';
import {Route, Switch} from 'react-router-dom';
import decode from "jwt-decode";
import './App.css';
import Alert from 'react-s-alert';
import Header from "./components/header";
import Login from "./pages/login";
import Odds from "./pages/odds";
import EditOdd from "./pages/odds/edit";
import OddsChart from "./pages/odds/chart";
import Sports from "./pages/sports";
import Picks from "./pages/picks";
import EditPick from "./pages/picks/edit";
import EditSport from "./pages/sports/edit";
import Matches from "./pages/matches";
import Subscriptions from "./pages/subscriptions";
import RelatedOdds from "./pages/matches/odds";
import EditMatch from "./pages/matches/edit";
import NewMatch from "./pages/matches/newMatch";
import NewOdd from "./pages/matches/newOdd";
import Payments from "./pages/payments";
import Users from "./pages/users";
import UsersChart from "./pages/users/chart";
import Results from "./pages/results";
import EditResult from "./pages/results/edit";
import Socials from "./pages/socials";
import EditSocial from "./pages/socials/edit";
import NewSocial from "./pages/socials/new";
import NotFound from "./pages/notfound";
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';


class App extends Component {
  isUserAuthenticated = () => {
    const jwt = localStorage.getItem('token');

    if (!jwt) {
      return false;
    }

    jwt.replace("Bearer", "");
    const dateNow = new Date().getTime() / 1000;
    const { exp } =  decode(jwt);

    return dateNow < exp;
  }

  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) => {
          if (this.isUserAuthenticated()) {
            if (rest.path === "/users/stats/noheader") {
              return <Component {...props} />
            }

            return <><Header {...props} /><Component {...props} /></>
          }

          return <Redirect to={{ pathname: "/login", state: { from: props.location }}} />
        }
        }
      />
    );

    const Home = () => (
      <section id="page-content">
        <div className="body-content animated fadeIn">
          <h1>Home page</h1>
          (<Redirect to={{ pathname: "/matches" }} />)
        </div>
      </section>
    );

    return (
      <div className="App container">
        <Switch>
          <Route path="/login" component={Login} history={this.props.history} />
          <PrivateRoute path="/" component={Home} exact />
          <PrivateRoute path="/odds" component={Odds} exact />
          <PrivateRoute path="/odds/charts/:id" component={OddsChart} exact />
          <PrivateRoute path="/odds/edit/:id" component={EditOdd} exact />
          <PrivateRoute path="/sports" component={Sports} exact />
          <PrivateRoute path="/picks" component={Picks} exact />
          <PrivateRoute path="/picks/:id" component={EditPick} exact />
          <PrivateRoute path="/sports/:id" component={EditSport} exact />
          <PrivateRoute path="/subscriptions" component={Subscriptions} exact />
          <PrivateRoute path="/matches" component={Matches} exact />
          <PrivateRoute path="/matches/new" component={NewMatch} exact />
          <PrivateRoute path="/matches/:id" component={EditMatch} exact />
          <PrivateRoute path="/matches/:id/odds" component={RelatedOdds} exact />
          <PrivateRoute path="/matches/:id/odds/new" component={NewOdd} exact />
          <PrivateRoute path="/payments" component={Payments} exact />
          <PrivateRoute path="/users" component={Users} exact />
          <PrivateRoute path="/users/stats" component={UsersChart} exact />
          <PrivateRoute path="/users/stats/noheader" component={UsersChart} exact />
          <PrivateRoute path="/results" component={Results} exact />
          <PrivateRoute path="/results/:id" component={EditResult} exact />
          <PrivateRoute path="/socials" component={Socials} exact />
          <PrivateRoute path="/socials/edit/:id" component={EditSocial} exact />
          <PrivateRoute path="/socials/new" component={NewSocial} exact />
          <PrivateRoute component={NotFound} />
        </Switch>
        <Alert stack={{limit: 1}} />
      </div>
    );
  }
}

export default App;

import React, { Component } from "react";
import { Button, Form, Col, FormGroup, FormControl, ControlLabel, DropdownButton, MenuItem } from "react-bootstrap";
import PropTypes from 'prop-types';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError, alertSuccess } from '../../../components/alerts';
import "./style.css";


class EditPick extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: {},
      finishRequiredFields: ['MoneyLine'],
      otherRequiredFileds: ['Final']
    };
  }

  componentDidMount = async () => {
    try {
      const { id } = this.props.match.params;
      const { data } = await api.get(`${CONFIG.api_url}/picks/${id}`);
      this.setState({ result: data.pick });
    } catch (e) {
      alertError('Error while loading result');
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  getResultFieldsList = () => {
    const list = [];
    const fields = ['id','AwayPitcher','DeviationSpread','DeviationTotal','DrawCoef','DrawLine','HomePitcher','MoneyLine','MoneyLineCoef','PointSpread','ROIDrawLine','ROIMoneyLine','ROISpread','ROITotal','Total','TotalCoef','TotalScores'];
    const { result } = this.state;
    if (typeof result !== 'undefined' && result.Match) {
      list.push(
        <FormGroup controlId='MatchDetails' key={'MatchDetails'}>
          <Col sm={4} componentClass={ControlLabel}> {'MatchDetails'}</Col>
          <Col sm={4}>
            <FormControl type="text" value={result.Match.Details} onChange={this.handleChangeResultField} />
          </Col>
        </FormGroup>);
    }
    for (const field in fields) {
      let required = '';
      list.push(
        <FormGroup controlId={fields[field]} key={field}>
          <Col sm={4} componentClass={ControlLabel}> {fields[field]} {required}</Col>
          <Col sm={4}>
            <FormControl type="text" value={result[fields[field]]} onChange={this.handleChangeResultField} />
          </Col>
        </FormGroup>);
    }
   
    

    return list;
  }

  
  handleCancel = async () => {
    const { history } = this.props;
    history.push('/picks')
  }

  render() {
    const resultFields = this.getResultFieldsList();

    return (
      <Form horizontal>
        {resultFields}
        <Col smOffset={4} sm={4}>
          <Button bsStyle="danger" bsSize="large" type="submit" onClick={this.handleCancel}> Cancel </Button>
        </Col>
      </Form>
    );
  }
}

export default EditPick;

EditPick.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

import React, { Component } from "react";
import { Button, Form, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import PropTypes from 'prop-types';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError, alertSuccess } from '../../../components/alerts';
import "./style.css";


class EditSport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sport: {},
      requiredFields: ['id', 'Name']
    };
  }

  componentDidMount = async () => {
    try {
      const { id } = this.props.match.params;
      const { data } = await api.get(`${CONFIG.api_url}/sports/${id}`);
      this.setState({ sport: data.sports });
    } catch (e) {
      alertError('Error while loading sport');
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  getSportFieldsList = () => {
    const list = [];
    const fields = ['id', 'Name', 'oddsCount', 'picksCount'];
    const { sport, requiredFields } = this.state;

    for (const field in fields) {
      let required = '';

      if (requiredFields.includes(fields[field])) {
        required = <span className="red">*</span>
      }

      const value = sport[fields[field]];
      list.push(
        <FormGroup controlId={fields[field]} key={field}>
          <Col sm={4} componentClass={ControlLabel}> {fields[field]} {required}</Col>
          <Col sm={4}>
            <FormControl type="text" value={value || value === 0 ? value : ''} onChange={this.handleChangeSportField} />
          </Col>
        </FormGroup>);
    }

    return list;
  }

  handleCancel = async () => {
    const { history } = this.props;
    history.push('/sports');
  }

  handleChangeSportField = (event) => {
    const sport = Object.assign({}, this.state.sport);
    sport[event.target.id] = event.target.value;
    this.setState({ sport });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { sport, requiredFields } = this.state;

      for (const field in requiredFields) {
        const value = sport[requiredFields[field]];

        if (!value && value !== 0) {
          return alertError(`${requiredFields[field]} field is required!`);
        }
      }

      const { data } = await api.patch(`${CONFIG.api_url}/sports/${sport.id}`, sport);

      if (data.success) {
        const { history } = this.props;
        history.push('/sports');
        alertSuccess('Sport saved succesfully!');
      } else if (data.error) {
        alertError(data.error);
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error || e);
    }
  }

  render() {
    const sportFields = this.getSportFieldsList();

    return (
      <Form horizontal onSubmit={this.handleSubmit}>
        {sportFields}
        <Col smOffset={4} sm={4}>
          <Button className="save-button" bsStyle="success" bsSize="large" type="submit"> Save </Button>
          <Button bsStyle="danger" bsSize="large" type="submit" onClick={this.handleCancel}> Cancel </Button>
        </Col>
      </Form>
    );
  }
}

export default EditSport;

EditSport.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

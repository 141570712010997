import React, { Component } from "react";
import { Button, Form, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import PropTypes from 'prop-types';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError, alertSuccess } from '../../../components/alerts';
import "./style.css";


class EditMatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      match: {},
      requiredFields: ['MatchID', 'HomeTeam', 'AwayTeam', 'Sport', 'MatchTime']
    };
  }

  componentDidMount = async () => {
    try {
      const { id } = this.props.match.params;
      const { data: match } = await api.get(`${CONFIG.api_url}/matches/${id}`);
      this.setState({ match: match.match });
    } catch (e) {
      alertError('Error while loading charts');
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  getMatchFieldsList = () => {
    const list = [];
    const fields = ['MatchID', 'HomeTeam', 'AwayTeam', 'Sport', 'MatchTime', 'Details', 'DisplayLeague', 'DisplayRegion', 'HomeROT', 'AwayROT', 'HomePitcher', 'AwayPitcher'];
    const { match, requiredFields } = this.state;

    for (const field in fields) {
      let required = '';

      if (requiredFields.includes(fields[field])) {
        required = <span className="red">*</span>
      }
      list.push(
        <FormGroup controlId={fields[field]} key={field}>
          <Col sm={4} componentClass={ControlLabel}> {fields[field]} {required} {fields[field] == 'MatchTime' ? '(UTC)': ''}</Col>
          <Col sm={4}>
            <FormControl type="text" value={match[fields[field]] || ''} onChange={this.handleChangeMatchField} />
          </Col>
        </FormGroup>);
    }

    return list;
  }

  handleEditOdd = (uid) => {
    const { history } = this.props;
    history.push(`/odds/edit/${uid}`);
  }

  handleCancel = async () => {
    const { history } = this.props;
    const { location: { search } } = this.props;
    const page = search.split('?cancel=')
    if (page.length > 0 && page[1] !== '') {
      history.push(page[1])  
    } else {
      history.push('/matches')
    }
  }

  handleChangeMatchField = (event) => {
    const match = Object.assign({}, this.state.match);
    match[event.target.id] = event.target.value;
    this.setState({ match });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { match, requiredFields } = this.state;

      for (const field in requiredFields) {
        if (!match[requiredFields[field]]) {
          return alertError(`${requiredFields[field]} field is required!`);
        }
      }

      const { data } = await api.patch(`${CONFIG.api_url}/matches/${match.MatchID}`, match);

      if (data.success) {
        alertSuccess('Match saved succesfully!');
      } else if (data.error) {
        alertError(data.error);
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error || e);
    }
  }

  render() {
    const matchFields = this.getMatchFieldsList();

    return (
      <Form horizontal onSubmit={this.handleSubmit}>
        {matchFields}
        <Col smOffset={4} sm={4}>
          <Button className="save-button" bsStyle="success" bsSize="large" type="submit"> Save </Button>
          <Button bsStyle="danger" bsSize="large" type="submit" onClick={this.handleCancel}> Cancel </Button>
        </Col>
      </Form>
    );
  }
}

export default EditMatch;

EditMatch.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

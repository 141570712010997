import { fetchOddsBegin,
  fetchOddsSuccess,
  fetchOddsError } from '../actions';
import CONFIG from '../config/config';
import api from '../config/api';


export const fetchOdds = async (dispatch, currentPage, limit, teamName = '', sportId = '') => {
  try {
    dispatch(fetchOddsBegin());
    const { data } = await api.get(`${CONFIG.api_url}/odds?page=${currentPage}&limit=${limit}&teamName=${teamName}&sport=${sportId}`);
    data.currentPage = currentPage;
    data.limit = limit;
    data.searchValue = teamName;
    data.sportId = sportId;
    dispatch(fetchOddsSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchOddsError(error));
  }
}

import React, { Component } from "react";
import { Panel, Row, Col, Label, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CONFIG from '../../config/config';
import api from '../../config/api';
import "./style.css";


class Sports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sports: []
    };
  }

  componentDidMount = async () => {
    try {
      const { data } = await api.get(`${CONFIG.api_url}/sports`);
      this.setState({sports: data.sports});
    } catch (e) {
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  handleEditSport = (id) => {
    const { history } = this.props;
    history.push(`/sports/${id}`);
  }

  getSportsList = () => {
    const { sports } = this.state;
    const list = [];

    if (sports.length > 0) {
      sports.forEach((sport) => {
        list.push(
          <Row className="match" key={sport.id}>
            <Col md={2}> {sport.id} </Col>
            <Col md={3}> {sport.Name} </Col>
            <Col md={3}> {sport.oddsCount} </Col>
            <Col md={3}> {sport.picksCount} </Col>
            <Col md={1} className="buttons-block">
              <Button className="action-btn edit-button" bsStyle="warning" onClick={() => this.handleEditSport(sport.id)}>
                Edit
              </Button>
            </Col>
          </Row>)
      })
    }

    return list;
  }


  render() {
    const sportsList = this.getSportsList();

    return (
      <Panel style={{"border": "none"}}>
        <Panel.Heading>
          <Row>
            <Col md={2}> id </Col>
            <Col md={3}> Name </Col>
            <Col md={3}> oddsCount </Col>
            <Col md={3}> picksCount </Col>
            <Col md={1} />
          </Row>
        </Panel.Heading>
        <Panel.Body style={{"padding": "0 15px"}}>
          {sportsList.length > 0 ? sportsList : <Label style={{'backgroundColor': 'white', 'color': '#777', 'fontSize': 34, 'padding': '0 0 0 40%', "lineHeight": 2}}>No sports found</Label>}
        </Panel.Body>
      </Panel>
    );
  }
}

export default Sports;

Sports.propTypes = {
  history: PropTypes.object
};

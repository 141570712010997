import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "../../../store/context";
import './style.scss';


class RoomSearch extends Component {
  
  constructor(props) {
    super(props);
    const { Status,  UserEmail, SportId} = this.props;
    this.state = {
      status: Status ? Status: 'unknown',
      sportId: SportId ? SportId: -1,
      userEmail: UserEmail? UserEmail: '',
    };
  }

  handleSearchChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSearch = (event) => {
    event.preventDefault();
    const { status, sportId, userEmail } = this.state
    const { onSearch } = this.props;
    onSearch(status, sportId, userEmail)

  }

  render() {
    const { showDetailedSearch } = this.state;
    const { Sports, Status,  UserEmail, SportId} = this.props;
    const selectedStatus = Status == '' ? 'unknown': Status
    return (
      <form className="result-search">
        {showDetailedSearch &&
          <div className="row detailed">
            <div className="col-md-12">


            </div>
          </div>
        }

        <div className="row">
          <div className="col-md-12">
            <div className="form-group col-md-3">
              <select className="form-control" id="sportId" onChange={this.handleSearchChange}>
                <option value="-1">All Sports</option>
                {Sports.map((sport) => <option key={sport.id} value={sport.id} selected={SportId==sport.id}>{sport.Name.toUpperCase()}</option>)}
              </select>
            </div>

            <div className="form-group col-md-3">
              <select className="form-control" id="status" onChange={this.handleSearchChange} defaultValue={selectedStatus}>
                <option value="unknown">All Picks</option>
                <option value="graded">Graded</option>
                <option value="manual">Require Manual Check</option>
                <option value="ungraded">Not Graded</option>
              </select>
            </div>
            <div className="form-group col-md-3">
              <input className="form-control" id="userEmail" type="text" placeholder="Consultant email" onChange={this.handleSearchChange} defaultValue={UserEmail}/>
            </div>

            <div className="form-group col-md-4">
              <button type="submit" className="btn btn-default" onClick={this.handleSearch}>Search</button>
            </div>
          </div>
        </div>

        <div className="row show-detailed">
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(RoomSearch);

RoomSearch.propTypes = {
  onSearch: PropTypes.func,
  Sports: PropTypes.array,
  Status: PropTypes.string,
  UserEmail: PropTypes.string,
  SportId: PropTypes.number
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "../../../store/context";
import './style.scss';


class RoomSearch extends Component {
  static propTypes = {
    Sports: PropTypes.array,
    dispatch: PropTypes.func
  }

  state = {
    finalType: 'unknown',
    sportId: -1,
  };

  handleSearchChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSearch = (event) => {
    event.preventDefault();
    const {finalType, sportId } = this.state
    const { onSearch } = this.props;
    onSearch(finalType, sportId)
    
  }

  render() {
    const { showDetailedSearch } = this.state;
    const { Sports } = this.props;

    return (
      <form className="result-search">
        {showDetailedSearch &&
          <div className="row detailed">
            <div className="col-md-12">

              
            </div>
          </div>
        }

        <div className="row">
          <div className="col-md-12">
          <div className="form-group col-md-3">
              <select className="form-control" id="sportId" onChange={this.handleSearchChange}>
                <option disabled hidden>Sport</option>
                <option value="-1">All Sports</option>
                {Sports.map((sport) => <option key={sport.id} value={sport.id}>{sport.Name.toUpperCase()}</option>)}
              </select>
            </div>

            <div className="form-group col-md-3">
              <select className="form-control" id="finalType" onChange={this.handleSearchChange}>
              <option value="unknown">All Final Type</option>
                <option  value="finished">Finished</option>
                <option  value="canceled">Canceled</option>
                <option  value="notfinished">NotFinished</option>
                <option value="postponed">Postponed</option>
                <option value="abandoned">Abandoned</option>
                
              </select>
            </div>
            {/* <div className="form-group col-md-3">
              <select className="form-control" id="type" onChange={this.handleSearchChange}>
                <option disabled hidden>Room Type</option>
                <option value="null">Room Type</option>
                <option  value={2}>Public</option>
                <option value={3}>Private</option>
              </select>
            </div> */}

            <div className="form-group col-md-4">
              <button type="submit" className="btn btn-default" onClick={this.handleSearch}>Search</button>
            </div>
          </div>
        </div>

        <div className="row show-detailed">
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(RoomSearch);

RoomSearch.propTypes = {
  onSearch: PropTypes.func,
  Sports: PropTypes.array,
};

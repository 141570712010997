import { fetchMatchesBegin,
  fetchMatchesSuccess,
  fetchMatchesError } from '../actions';
import CONFIG from '../config/config';
import api from '../config/api';


export const fetchMatches = async (dispatch, currentPage, limit, teamName = '', sportId = '', matchTime = 'Future') => {
  try {
    dispatch(fetchMatchesBegin());
    const { data } = await api.get(`${CONFIG.api_url}/matches?page=${currentPage}&limit=${limit}&teamName=${teamName}&sport=${sportId}&matchTime=${matchTime}`);
    data.currentPage = currentPage;
    data.limit = limit;
    data.searchValue = teamName;
    data.sportId = sportId;
    data.matchTime = matchTime;
    dispatch(fetchMatchesSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchMatchesError(error));
  }
}

import { fetchSubscriptionsSuccess } from '../actions';
import CONFIG from '../config/config';
import api from '../config/api';
import { alertError } from '../actions/alerts';


export const fetchSubscriptions = async (dispatch, currentPage, limit, status=null, sportId=null) => {
  try {
    const { data } = await api.get(`${CONFIG.api_url}/subscriptions?page=${currentPage}&limit=${limit}&status=${status}&sportId=${sportId}`);
    data.currentPage = currentPage;
    data.limit = limit;
    dispatch(fetchSubscriptionsSuccess(data));
    console.log(data)
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    alertError(error)
  }
}

export const updateSubscriptionStatus = async (id, postData) => {
  try {
      const { data } = await api.post(`${CONFIG.api_url}/subscriptions/${id}`, postData);
      return [data, null]

  } catch(error) {
    console.log(error)
    return [null, error]
  }
}
import { fetchSportsBegin,
    fetchSportsSuccess,
    fetchSportsError, } from '../actions';
  import CONFIG from '../config/config';
  import api from '../config/api';
  
  
  export const getSports = async (dispatch) => {
    try {
      fetchSportsBegin();
      const { data } = await api.get(`${CONFIG.api_url}/sports`);
      dispatch(fetchSportsSuccess(data));
    } catch (error) {
      console.log(error);  //eslint-disable-line no-console
      dispatch(fetchSportsError(error));
    }
  }
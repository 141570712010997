import React, { Component } from "react";
import { DropdownButton, MenuItem, Row, Col, Pagination, Panel, Label } from "react-bootstrap";
import i from 'immutable';
import PropTypes from 'prop-types';

import { alertError, alertSuccess } from '../../components/alerts';
import { Loader } from '../../components/loader';
import { fetchMatches } from '../../api/matches';
import { connect } from '../../store/context';
import CONFIG from '../../config/config';
import { ModalDialog } from '../../components/modal'
import api from '../../config/api';
import FilterPanel from './FilterPanel';
import "./style.css";


class Matches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      limit: 10,
      showModal: false,
      selectedId: null
    };
  }

  componentDidMount = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { dispatch } = this.props;
      fetchMatches(dispatch, currentPage, limit);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleEditMatch = (uid) => {
    const { history } = this.props;
    history.push(`/matches/${uid}`);
  }

  handleShowOdds = (uid) => {
    const { history } = this.props;
    history.push(`/matches/${uid}/odds`);
  }

  handleAddNewOdd = (uid) => {
    const { history } = this.props;
    history.push(`/matches/${uid}/odds/new`);
  }

  handleDeleteMatch = async () => {
    try {
      const { selectedId } = this.state;
      const { data } = await api.delete(`${CONFIG.api_url}/matches/${selectedId}`);

      if (data.success) {
        alertSuccess(`${data.message}`);
        this.handleHideModal();
        const { history } = this.props;
        history.push(`/matches`);
      } else if (data.error) {
        alertError(data.error);
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error || e);
    }
  }

  getMatchesList = () => {
    const { matches } = this.props;
    const result = [];

    if (matches.length > 0) {
      matches.forEach((match) => {
        let matchTime;

        if (match.MatchTime) {
          matchTime = match.MatchTime.replace('T', ' ').replace(':00.000Z', '');
        }

        result.push(
          <Row className="match" key={match.id}>
            <Col md={4}> {match.MatchID} </Col>
            <Col md={3}> {match.AwayTeam} </Col>
            <Col md={2}> {match.HomeTeam} </Col>
            <Col md={2}> {matchTime} </Col>
            <Col md={1} className="actions-dropdown">
              <DropdownButton pullRight title="Actions" id={`dropdown-${match.MatchID}`} >
                <MenuItem eventKey="1" onClick={() => this.handleEditMatch(match.MatchID)}>Edit Match</MenuItem>
                <MenuItem eventKey="2" onClick={() => this.handleShowModal(match.MatchID)}>Delete Match</MenuItem>
                <MenuItem divider />
                <MenuItem eventKey="3" onClick={() => this.handleShowOdds(match.MatchID)}>Show Odds</MenuItem>
                <MenuItem eventKey="4" onClick={() => this.handleAddNewOdd(match.MatchID)}>Add New Odd</MenuItem>
              </DropdownButton>
              {/* <Button className="action-btn" bsStyle="warning" onClick={() => this.handleEditMatch(match.MatchID)}>
                <Glyphicon glyph="eye-open" />
              </Button>
              <Button className="action-btn" bsStyle="primary" onClick={() => this.handleEditMatch(match.MatchID)}>
                <Glyphicon glyph="picture" />
              </Button>
              <Button className="action-btn" bsStyle="danger" onClick={() => this.handleEditMatch(match.MatchID)}>
                <Glyphicon glyph="remove" />
              </Button> */}
            </Col>
          </Row>)
      })
    }

    return result;
  }

  handleClickPagination = async (newPage) => {
    try {
      const { currentPage, limit } = this.state;

      if (currentPage !== newPage) {
        const { dispatch, searchValue, sportId, matchTime } = this.props;
        fetchMatches(dispatch, newPage, limit, searchValue, sportId, matchTime);
        this.setState({currentPage: newPage})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateFirst = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { sportId } = this.props;

      if (currentPage !== 1) {
        const { dispatch, searchValue, matchTime } = this.props;
        fetchMatches(dispatch, 1, limit, searchValue, sportId, matchTime);
        this.setState({currentPage: 1})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateLast = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { pages } = this.props;

      if (currentPage !== pages) {
        const { dispatch, searchValue, sportId, matchTime } = this.props;
        fetchMatches(dispatch, pages, limit, searchValue, sportId, matchTime);
        this.setState({currentPage: pages})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  getPagination = () => {
    const { pages, currentPage } = this.props;
    const active = currentPage;
    let items = [];
    const delta = 5;
    const left = pages - currentPage < delta ? pages - 10 : currentPage - delta;
    const right = currentPage < delta ? 12 : currentPage + delta + 1;
    pages > 1 ? items.push(<Pagination.First onClick={this.handleNavigateFirst} key="first" />) : items = [];

    for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
      if (pageNumber >= left && pageNumber < right) {
        items.push(<Pagination.Item active={pageNumber === active} onClick={() => this.handleClickPagination(pageNumber)} key={pageNumber}>{pageNumber}</Pagination.Item>);
      }
    }

    pages > 1 ? items.push(<Pagination.Last onClick={this.handleNavigateLast} key="last" />) : items = [];
    return items;
  }

  handleShowModal = (itemId) => {
    this.setState({showModal: true, selectedId: itemId})
  }

  handleHideModal = () => {
    this.setState({showModal: false, selectedId: null})
  }

  render() {
    const { showModal } = this.state;
    const { loading } = this.props;
    const matches = this.getMatchesList();
    const pages = this.getPagination();

    return (
      <>
        <FilterPanel history={this.props.history} />
        <Panel className="matches-panel">
          <Panel.Heading>
            <Row className="panel-title-align">
              <Col md={4}> MatchID </Col>
              <Col md={3}> AwayTeam </Col>
              <Col md={2}> HomeTeam </Col>
              <Col md={2}> matchTime </Col>
              <Col md={1} />
            </Row>
          </Panel.Heading>
          <Panel.Body className="matches-panel-body">
            {loading && <Loader />}
            {!loading && matches.length === 0 && <Label className="no-results-found">No games found</Label>}
            {matches.length > 0 && matches}
          </Panel.Body>
        </Panel>
        <Pagination bsSize="large">{pages}</Pagination>
        {showModal ? <ModalDialog onCancel={this.handleHideModal} onConfirm={this.handleDeleteMatch} /> : ''}
      </>
    );
  }
}

const select = (state) => {
  const matches = state.getIn(['matches', 'list'], i.List()).toJS();
  const pages = state.getIn(['matches', 'pages'], 0);
  const limit = state.getIn(['matches', 'limit'], 0);
  const searchValue = state.getIn(['matches', 'searchValue'], '');
  const currentPage = state.getIn(['matches', 'currentPage'], '');
  const sportId = state.getIn(['matches', 'sportId'], '');
  const matchTime = state.getIn(['matches', 'matchTime'], '');
  const loading = state.getIn(['matches', 'loading'], false);

  return { matches, pages, limit, searchValue, currentPage, sportId, matchTime, loading };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Matches);

Matches.propTypes = {
  currentPage: PropTypes.number,
  // dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  matches: PropTypes.array,
  pages: PropTypes.number
};

import { Map, List, fromJS } from 'immutable';
import {
  FETCH_MATCHES_BEGIN,
  FETCH_MATCHES_SUCCESS,
  FETCH_MATCHES_FAILURE,
  CHANGE_SPORT_FILTER_SUCCESS
} from '../actions';


const initialState = Map({
  list: List(),
  pages: 0,
  currentPage: 1,
  limit: 10,
  searchValue: '',
  sportId: '',
  loading: false,
  error: null
});

export default function matchesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MATCHES_BEGIN:
      state = state.set('loading', true);
      break;
    case FETCH_MATCHES_SUCCESS:
      state = state.set('list', fromJS(action.data.matches.matches));
      state = state.set('pages', fromJS(action.data.matches.pages));
      state = state.set('currentPage', fromJS(action.data.matches.currentPage));
      state = state.set('limit', fromJS(action.data.matches.limit));
      state = state.set('searchValue', fromJS(action.data.matches.searchValue));
      state = state.set('sportId', fromJS(action.data.matches.sportId));
      state = state.set('matchTime', fromJS(action.data.matches.matchTime));
      state = state.set('loading', false);
      break;
    case FETCH_MATCHES_FAILURE:
      state = state.set('error', action.error);
      state = state.set('loading', false);
      break;
    case CHANGE_SPORT_FILTER_SUCCESS:
      state = state.set('sportId', fromJS(action.data.id));
      break;

    default:
      return state;
  }

  return state;
}

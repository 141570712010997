import React, { Component } from "react";
import { Button, Col, Row, Panel, Glyphicon } from "react-bootstrap";
import PropTypes from 'prop-types';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { ModalDialog } from '../../../components/modal';
import { alertError, alertSuccess } from '../../../components/alerts';
import "./style.css";


class RelatedOdds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      odds: {},
      showModal: false,
      selectedId: null
    };
  }

  componentDidMount = async () => {
    try {
      const { id } = this.props.match.params;
      const { data: odds } = await api.get(`${CONFIG.api_url}/matches/${id}/odds`);
      this.setState({ odds: odds.odds });
    } catch (e) {
      alertError('Error while loading odds');
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  handleEditOdd = (uid) => {
    const { history } = this.props;
    history.push(`/odds/edit/${uid}`)
  }

  handleDeleteOdd = async () => {
    try {
      const { selectedId } = this.state;
      const { data } = await api.delete(`${CONFIG.api_url}/odds/${selectedId}`);

      if (data.success) {
        alertSuccess(`${data.message}`);
        this.handleHideModal();
        const { id } = this.props.match.params;
        const { history } = this.props;
        history.push(`/matches/${id}/odds`);
      } else if (data.error) {
        alertError(data.error);
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error || e);
    }
  }

  handleShowHistory = (uid) => {
    const { history } = this.props;
    history.push(`/odds/charts/${uid}`);
  }

  getOddsList = () => {
    const list = [];
    const { odds } = this.state;

    for (const odd in odds) {
      list.push(
        <Row className="match" key={odds[odd].id}>
          <Col md={3}> {odds[odd].OddID} </Col>
          <Col md={2}> {odds[odd].MoneyLineAway} </Col>
          <Col md={2}> {odds[odd].MoneyLineHome} </Col>
          <Col md={2}> {odds[odd].PointSpreadAway} </Col>
          <Col md={1}> {odds[odd].PointSpreadHome} </Col>
          <Col md={2} className="buttons-block">
            <Button className="action-btn buttons-margin-right" bsStyle="primary" onClick={() => this.handleShowHistory(odds[odd].OddID)}>
              <Glyphicon glyph="picture" />
            </Button>
            <Button className="action-btn buttons-margin-right" bsStyle="warning" onClick={() => this.handleEditOdd(odds[odd].OddID)}>
              <Glyphicon glyph="edit" />
            </Button>
            <Button className="action-btn" bsStyle="danger" onClick={() => this.handleShowModal(odds[odd].OddID)}>
              <Glyphicon glyph="remove" />
            </Button>
          </Col>
        </Row>
      );
    }

    return list;
  }

  handleShowModal = (itemId) => {
    this.setState({showModal: true, selectedId: itemId})
  }

  handleHideModal = () => {
    this.setState({showModal: false, selectedId: null})
  }

  render() {
    const { showModal } = this.state;
    const oddslist = this.getOddsList();

    return (
      <>
        <Panel className="odds-panel-border">
          <Panel.Heading>
            <Row className="header-row" key="title">
              <Col sm={3}> OddID </Col>
              <Col sm={2}> MoneyLineAway </Col>
              <Col sm={2}> MoneyLineHome </Col>
              <Col sm={2}> PointSpreadAway </Col>
              <Col sm={2}> PointSpreadHome </Col>
              <Col sm={1} />
            </Row>
          </Panel.Heading>
          <Panel.Body>
            {oddslist}
          </Panel.Body>
        </Panel>
        {showModal ? <ModalDialog onCancel={this.handleHideModal} onConfirm={this.handleDeleteOdd} /> : ''}
      </>
    );
  }
}

export default RelatedOdds;

RelatedOdds.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

import React, { Component } from "react";
import { Panel, Col, Row, DropdownButton, Glyphicon, FormControl, Label, MenuItem, Button } from "react-bootstrap";
import PropTypes from 'prop-types';
import { changeSportFilterSuccess } from '../../actions';
import { fetchMatches } from '../../api/matches';
import { alertError } from '../../components/alerts';
import { connect } from '../../store/context';
import CONFIG from '../../config/config';
import api from '../../config/api';
import "./style.css";


class FilterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      sportsList: null,
      selectedSport: '',
      selectedSportId: null,
      matchTime: "Future"
    }
  }

  componentDidMount = async () => {
    try {
      const { data } = await api.get(`${CONFIG.api_url}/sports`);

      if (data && data.sports) {
        this.setState({sportsList: data.sports});
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleAddMatch = () => {
    const { history } = this.props;
    history.push('/matches/new');
  }

  handleSearchChange = (event) => {
    try {
      const searchValue = event.target.value;
      this.setState({ search: searchValue });
      const { limit, sportId } = this.props;
      const currentPage = 1;
      const { dispatch } = this.props;
      const { matchTime } = this.state;
      fetchMatches(dispatch, currentPage, limit, searchValue, sportId, matchTime);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  getSportsDropdown = () => {
    const { sportsList, selectedSportId } = this.state;
    const result = [];
    let evk = 1;
    result.push(
      <MenuItem eventKey={evk} key="All" onClick={this.handleSelectSport} active={!selectedSportId}> All Sports </MenuItem>
    )
    result.push(
      <MenuItem divider key="divider" />
    )

    if (sportsList && sportsList.length > 0) {
      sportsList.forEach((sport) => {
        evk += 1;
        result.push(
          <MenuItem eventKey={evk} key={sport.Name} id={sport.id} onClick={this.handleSelectSport} active={selectedSportId === sport.id}> {sport.Name.toUpperCase()} </MenuItem>
        )
      })
    }

    return result;
  }

  handleSelectSport = (event) => {
    try {
      const { dispatch, limit } = this.props;
      dispatch(changeSportFilterSuccess(event.target.id));
      const { search, matchTime } = this.state;
      fetchMatches(dispatch, 1, limit, search, event.target.id, matchTime);
      this.setState({selectedSport: event.target.text, selectedSportId: parseInt(event.target.id)})
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
  }

  handleChangeTime = (event) => {
    try {
      const matchTime = event.target.id
      this.setState({matchTime})
      const { search } = this.state;
      const { limit, sportId } = this.props;
      const currentPage = 1;
      const { dispatch } = this.props;
      fetchMatches(dispatch, currentPage, limit, search, sportId, matchTime);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  render() {
    const sportsDropdownItems = this.getSportsDropdown();
    const { selectedSport, matchTime } = this.state;

    return (
      <Panel className="filter-panel">
        <Panel.Heading>
          <Row className="show-grid">
            <Col md={2}>
              <Button bsStyle="success" className="filter-button" onClick={this.handleAddMatch}>New Match</Button>
            </Col>
            <Col md={3}>
              <Label className="filter-label">Sport: </Label>
              <DropdownButton title={selectedSport || 'All Sports'} id="sports-filter">
                {sportsDropdownItems}
              </DropdownButton>
            </Col>
            <Col md={3}>
              <Label className="filter-label"> Match Date: </Label>
              <DropdownButton title={matchTime || 'Future'} id="sports-filter">
                <MenuItem eventKey="1" id="Future" onClick={this.handleChangeTime} active={!matchTime || matchTime === "Future"}> Future </MenuItem>
                <MenuItem eventKey="2" id="Past" onClick={this.handleChangeTime} active={matchTime === "Past"}> Past </MenuItem>
              </DropdownButton>
            </Col>
            <Col md={4}>
              <Col xs={10} md={10}>
                <FormControl type="text" placeholder="Search by Team" value={this.state.search} onChange={this.handleSearchChange} onKeyDown={this.handleKeyPress} />
              </Col>
              <Col md={2}>
                <Glyphicon glyph="search" className="filter-search-icon" />
              </Col>
            </Col>
          </Row>
        </Panel.Heading>
      </Panel>
    );
  }
}

const select = (state) => {
  const limit = state.getIn(['matches', 'limit'], 0);
  const searchValue = state.getIn(['matches', 'searchValue'], '');
  const sportId = state.getIn(['matches', 'sportId'], '');

  return { limit, searchValue, sportId };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(FilterPanel);

FilterPanel.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.object,
  limit: PropTypes.number,
  sportId: PropTypes.string
};

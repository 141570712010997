import React, { Component } from "react";
import { DropdownButton, MenuItem, Row, Col, Pagination, Panel, Label } from "react-bootstrap";
import i from 'immutable';
import PropTypes from 'prop-types';

import { alertError, alertSuccess } from '../../components/alerts';
import { Loader } from '../../components/loader';
import { fetchSubscriptions, updateSubscriptionStatus } from '../../api/subscriptions';
import { connect } from '../../store/context';
import CONFIG from '../../config/config';
import { ModalDialog } from '../../components/modal'
import api from '../../config/api';
import FilterPanel from './FilterPanel';
import "./style.css";
import moment from 'moment'
import { alertErrorText, alertSuccessWithConfirmaiton } from "../../actions/alerts";

class Matches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      limit: 10,
      showModal: false,
      selectedId: null
    };
  }

  componentDidMount = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { dispatch } = this.props;
      fetchSubscriptions(dispatch, currentPage, limit);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleEditMatch = (uid) => {
    const { history } = this.props;
    history.push(`/matches/${uid}`);
  }

  handleShowOdds = (uid) => {
    const { history } = this.props;
    history.push(`/matches/${uid}/odds`);
  }

  handleAddNewOdd = (uid) => {
    const { history } = this.props;
    history.push(`/matches/${uid}/odds/new`);
  }

  handleDeleteMatch = async () => {
    try {
      const { selectedId } = this.state;
      const { data } = await api.delete(`${CONFIG.api_url}/matches/${selectedId}`);

      if (data.success) {
        alertSuccess(`${data.message}`);
        this.handleHideModal();
        const { history } = this.props;
        history.push(`/matches`);
      } else if (data.error) {
        alertError(data.error);
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error || e);
    }
  }

  blockSubscription = async (id) => {
    const result = await alertSuccessWithConfirmaiton(`Block Subscription`,
      `Subscription will not be shown to the new users for purchase and any picks will not be shown in the pick feed`);

    if (result.value) {
      const [data, err] = await updateSubscriptionStatus(id, {status: 3})
      if (err) {
        alertError(err)
      }
    }
  }
  unblockSubscription = async(id) => {
    const result = await alertSuccessWithConfirmaiton(`Unblock Subscription`,
      `Subscription will become active and will be shown to users for purchase`);

    if (result.value) {
      const [data, err] = await updateSubscriptionStatus(id, {status: 1})
      if (err) {
        alertError(err)
      }
    }
  }
  approveSubscription = async (id) => {
    const result = await alertSuccessWithConfirmaiton(`Approve Subscription`,
      `All the payments relevant to subscription will be deposited to consultant account`);

    if (result.value) {
      const [data, err] = await updateSubscriptionStatus(id, {status: 4})
      if (err) {
        alertError(err)
      }
    }
  }
  cancelSubscription = async (id) => {
    const result = await alertSuccessWithConfirmaiton(`Cancel Subscription`,
      `All users will be refunded the amount they paid to buy this subscription`);

    if (result.value) {
      const [data, err] = await updateSubscriptionStatus(id, {status: 5})
      if (err) {
        alertError(err)
      }
    }
  }

  getMatchesList = () => {
    const { subscriptions } = this.props;
    const result = [];

    if (subscriptions.length > 0) {
      subscriptions.forEach((subscription) => {

        result.push(
          <Row className="match" key={subscription.id}>
            <Col md={4}> {subscription.name} </Col>
            <Col md={3}> {subscription.picksCycle} </Col>
            <Col md={2}> {moment(subscription.startDate).format('LL')} </Col>
            <Col md={2}> {moment(subscription.endDate).format('LL')} </Col>
            <Col md={1} className="actions-dropdown">
              <DropdownButton pullRight title="Actions" id={`dropdown-${subscription.id}`} >
                {(subscription.status == 1 || subscription.status == 0) && <MenuItem eventKey="2" onClick={() => this.blockSubscription(subscription.id)}>Block</MenuItem>}
                {subscription.status == 3 && <MenuItem onClick={() => this.unblockSubscription(subscription.id)}>Unblock</MenuItem>}
                {subscription.status == 2 && <MenuItem onClick={() => this.approveSubscription(subscription.id)}>Approve</MenuItem>}
                {subscription.status == 2 && <MenuItem onClick={() => this.cancelSubscription(subscription.id)}>Cancel</MenuItem>}
                <MenuItem divider />
                
              </DropdownButton>

            </Col>
          </Row>)
      })
    }

    return result;
  }

  handleClickPagination = async (newPage) => {
    try {
      const { currentPage, limit } = this.state;

      if (currentPage !== newPage) {
        const { dispatch, searchValue, sportId, matchTime } = this.props;
        fetchSubscriptions(dispatch, newPage, limit, searchValue, sportId, matchTime);
        this.setState({currentPage: newPage})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateFirst = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { sportId } = this.props;

      if (currentPage !== 1) {
        const { dispatch, searchValue, matchTime } = this.props;
        fetchSubscriptions(dispatch, 1, limit, searchValue, sportId, matchTime);
        this.setState({currentPage: 1})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateLast = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { pages } = this.props;

      if (currentPage !== pages) {
        const { dispatch, searchValue, sportId, matchTime } = this.props;
        fetchSubscriptions(dispatch, pages, limit, searchValue, sportId, matchTime);
        this.setState({currentPage: pages})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  getPagination = () => {
    const { pages, currentPage } = this.props;
    const active = currentPage;
    let items = [];
    const delta = 5;
    const left = pages - currentPage < delta ? pages - 10 : currentPage - delta;
    const right = currentPage < delta ? 12 : currentPage + delta + 1;
    pages > 1 ? items.push(<Pagination.First onClick={this.handleNavigateFirst} key="first" />) : items = [];

    for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
      if (pageNumber >= left && pageNumber < right) {
        items.push(<Pagination.Item active={pageNumber === active} onClick={() => this.handleClickPagination(pageNumber)} key={pageNumber}>{pageNumber}</Pagination.Item>);
      }
    }

    pages > 1 ? items.push(<Pagination.Last onClick={this.handleNavigateLast} key="last" />) : items = [];
    return items;
  }

  handleShowModal = (itemId) => {
    this.setState({showModal: true, selectedId: itemId})
  }

  handleHideModal = () => {
    this.setState({showModal: false, selectedId: null})
  }

  render() {
    const { showModal } = this.state;
    const { loading } = this.props;
    const matches = this.getMatchesList();
    const pages = this.getPagination();

    return (
      <>
        <FilterPanel history={this.props.history} />
        <Panel className="matches-panel">
          <Panel.Heading>
            <Row className="panel-title-align">
              <Col md={4}> Name </Col>
              <Col md={3}> PicksCycle </Col>
              <Col md={2}> Start Date </Col>
              <Col md={2}> End Date </Col>
              <Col md={1} />
            </Row>
          </Panel.Heading>
          <Panel.Body className="matches-panel-body">
            {loading && <Loader />}
            {!loading && matches.length === 0 && <Label className="no-results-found">No games found</Label>}
            {matches.length > 0 && matches}
          </Panel.Body>
        </Panel>
        <Pagination bsSize="large">{pages}</Pagination>
        {showModal ? <ModalDialog onCancel={this.handleHideModal} onConfirm={this.handleDeleteMatch} /> : ''}
      </>
    );
  }
}

const select = (state) => {
  const subscriptions = state.getIn(['subscriptions', 'list'], i.List()).toJS();
  console.log(subscriptions)
  const pages = state.getIn(['matches', 'pages'], 0);
  const limit = state.getIn(['matches', 'limit'], 0);
  const searchValue = state.getIn(['matches', 'searchValue'], '');
  const currentPage = state.getIn(['matches', 'currentPage'], '');
  const sportId = state.getIn(['matches', 'sportId'], '');
  const matchTime = state.getIn(['matches', 'matchTime'], '');
  const loading = state.getIn(['matches', 'loading'], false);

  return { subscriptions, pages, limit, searchValue, currentPage, sportId, matchTime, loading };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Matches);

Matches.propTypes = {
  currentPage: PropTypes.number,
  // dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  matches: PropTypes.array,
  pages: PropTypes.number
};

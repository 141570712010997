import React, { Component } from "react";
import { Button, Form, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import PropTypes from 'prop-types';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError, alertSuccess } from '../../../components/alerts';
import "./style.css";


class EditSocial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socialText: {},
      sports: [],
      requiredFields: ['text', 'SportId']
    };
  }

  componentDidMount = async () => {
    try {
      const { id } = this.props.match.params;
      const { data } = await api.get(`${CONFIG.api_url}/social-post-text/${id}`);
      const { data: sportData } = await api.get(`${CONFIG.api_url}/sports`);
      this.setState({ socialText: data.text, sports: sportData.sports });
    } catch (e) {
      alertError('Error while loading socialText');
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  handleCancel = async () => {
    const { history } = this.props;
    history.push('/socials');
  }

  handleChangeSocialTextField = (event) => {
    const socialText = Object.assign({}, this.state.socialText);
    socialText[event.target.id] = event.target.value;

    this.setState({ socialText });
  }

  handleChangeSelectSportField = (event) => {
    const socialText = Object.assign({}, this.state.socialText);
    socialText.SportId = event.target.value;

    this.setState({ socialText });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { socialText, requiredFields } = this.state;

      for (const field in requiredFields) {
        const value = socialText[requiredFields[field]];

        if (!value && value !== 0) {
          return alertError(`${requiredFields[field]} field is required!`);
        }
      }

      const { data } = await api.patch(`${CONFIG.api_url}/social-post-text/${socialText.id}`, socialText);

      if (data.success) {
        const { history } = this.props;
        history.push('/socials');
        alertSuccess('Social Text saved succesfully!');
      } else if (data.error) {
        alertError(data.error);
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error || e);
    }
  }

  render() {
    const { sports, socialText } = this.state;

    const sportList = (
      sports.map((sport) => {
        return <option key={sport.id} value={sport.id}>{sport.Name.toUpperCase()}</option>
      })
    )

    return (
      <Form horizontal onSubmit={this.handleSubmit}>
        <FormGroup controlId="text" key="text">
          <Col sm={4} componentClass={ControlLabel}> Text <span className="red">*</span></Col>
          <Col sm={4}><FormControl type="text" value={socialText.text || ''} onChange={this.handleChangeSocialTextField} /></Col>
        </FormGroup>

        <FormGroup controlId="formControlsSelect">
          <Col sm={4} componentClass={ControlLabel}> Sport Name <span className="red">*</span></Col>
          <Col sm={4}>
            <FormControl componentClass="select" value={socialText.SportId} onChange={this.handleChangeSelectSportField}>
              {sportList}
            </FormControl>
          </Col>
        </FormGroup>
        <Col smOffset={4} sm={4}>
          <Button className="save-button" bsStyle="success" bsSize="large" type="submit"> Save </Button>
          <Button bsStyle="danger" bsSize="large" type="submit" onClick={this.handleCancel}> Cancel </Button>
        </Col>
      </Form>
    );
  }
}

export default EditSocial;

EditSocial.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

import axios from 'axios'
import CONFIG from './config';


const axiosApi = axios.create({
  baseURL: CONFIG.api_url
});

export const setAuthHeader = (token) => {
  axiosApi.defaults.headers.common['Authorization'] = token
}

const token = localStorage.getItem('token');

setAuthHeader(token && token !== 'undefined' ? JSON.parse(token) : '');

export default axiosApi;

import React, { Component } from "react";
import { Panel, Button, Pagination, Col, Row, Glyphicon, Label } from "react-bootstrap";
import moment from 'moment';
import i from 'immutable';
import PropTypes from 'prop-types';

import { alertError } from '../../components/alerts';
import { Loader } from '../../components/loader';
import { fetchOdds } from '../../api/odds';
import { connect } from '../../store/context';
import "./style.css";
import FilterPanel from './FilterPanel';


class Odds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { dispatch } = this.props;
      fetchOdds(dispatch, currentPage, limit);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleEdit = (id) => {
    const { history } = this.props;
    history.push(`/odds/edit/${id}`)
  }

  handleClickHistory = (id) => {
    const { history } = this.props;
    history.push(`/odds/charts/${id}`)
  }

  handleClickPagination = async (newPage) => {
    try {
      const { currentPage, limit } = this.state;

      if (currentPage !== newPage) {
        const { dispatch, searchValue, sportId } = this.props;
        fetchOdds(dispatch, newPage, limit, searchValue, sportId);
        this.setState({currentPage: newPage})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateFirst = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { sportId } = this.props;

      if (currentPage !== 1) {
        const { dispatch, searchValue } = this.props;
        fetchOdds(dispatch, 1, limit, searchValue, sportId);
        this.setState({currentPage: 1})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleNavigateLast = async () => {
    try {
      const { currentPage, limit } = this.state;
      const { pages } = this.props;

      if (currentPage !== pages) {
        const { dispatch, searchValue, sportId } = this.props;
        fetchOdds(dispatch, pages, limit, searchValue, sportId);
        this.setState({currentPage: pages})
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  getPagination = () => {
    const { pages, currentPage } = this.props;
    const active = currentPage;
    let items = [];
    const delta = 5;
    const left = pages - currentPage < delta ? pages - 10 : currentPage - delta;
    const right = currentPage < delta ? 12 : currentPage + delta + 1;
    pages > 1 ? items.push(<Pagination.First onClick={this.handleNavigateFirst} key="first" />) : items = [];

    for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
      if (pageNumber >= left && pageNumber < right) {
        items.push(<Pagination.Item active={pageNumber === active} onClick={() => this.handleClickPagination(pageNumber)} key={pageNumber}>{pageNumber}</Pagination.Item>);
      }
    }

    pages > 1 ? items.push(<Pagination.Last onClick={this.handleNavigateLast} key="last" />) : items = [];
    return items;
  }

  getOddsList = () => {
    const { odds } = this.props;
    const result = [];

    if (odds.length > 0) {
      odds.forEach((odd) => {
        result.push(
          <Panel bsStyle="info" className="odds-panel" key={odd.id}>
            <Panel.Heading className="odds-panel-head">
              <Panel.Title>
                GAME DATE: {moment(odd.Match.MatchTime).format('MMMM Do YYYY, h:mm:ss a')}
                <Button bsStyle="warning" className="odds-button-margin-right" onClick={() => this.handleEdit(odd.OddID)}>Edit</Button>
                <Button bsStyle="primary" className="odds-button-margin-right" onClick={() => this.handleClickHistory(odd.OddID)}>
                  Charts History
                  <Glyphicon className="odds-button-icon" glyph="picture" />
                </Button>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body className="odds-panel-body">
              <Row className="show-grid">
                <Col md={4}> Teams </Col>
                <Col md={2}> Spread </Col>
                <Col md={2}> Money Line </Col>
                <Col md={2}> Point Spread Line </Col>
                <Col md={2}> Over/Under Line </Col>
              </Row>
              <Row className="show-grid">
                <Col md={4}> {odd.Match.HomeTeam} </Col>
                <Col md={2}> {odd.PointSpreadHome} </Col>
                <Col md={2}> {odd.MoneyLineHome} </Col>
                <Col md={2}> {odd.PointSpreadHomeLine} </Col>
                <Col md={2}> {odd.OverLine} </Col>
              </Row>
              <Row className="show-grid">
                <Col md={4}> {odd.Match.AwayTeam} </Col>
                <Col md={2}> {odd.PointSpreadAway} </Col>
                <Col md={2}> {odd.MoneyLineAway} </Col>
                <Col md={2}> {odd.PointSpreadAwayLine} </Col>
                <Col md={2}> {odd.UnderLine} </Col>
              </Row>
            </Panel.Body>
            {/* <Panel.Footer style={{'padding': 5}}>
              <Row className="show-grid">
                <Col md={10}>
                  GAME DATE: {moment(odd.Match.MatchTime).format('MMMM Do YYYY, h:mm:ss a')}
                </Col>
              </Row>
            </Panel.Footer> */}
          </Panel>);
      });
    }

    return result;
  }

  render() {
    const oddsList = this.getOddsList();
    const pages = this.getPagination();
    const { loading } = this.props;

    return (
      <>
        <FilterPanel />
        {loading && <Loader />}
        {!loading && oddsList.length === 0 && <Label className="no-results-found">No results found</Label>}
        {oddsList.length > 0 && oddsList}
        <Pagination bsSize="large">{pages}</Pagination>
      </>
    );
  }
}

const select = (state) => {
  const odds = state.getIn(['odds', 'list'], i.List()).toJS();
  const pages = state.getIn(['odds', 'pages'], 0);
  const limit = state.getIn(['odds', 'limit'], 0);
  const searchValue = state.getIn(['odds', 'searchValue'], '');
  const currentPage = state.getIn(['odds', 'currentPage'], '');
  const sportId = state.getIn(['odds', 'sportId'], '');
  const loading = state.getIn(['odds', 'loading'], false);

  return { odds, pages, limit, searchValue, currentPage, sportId, loading };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Odds);

Odds.propTypes = {
  currentPage: PropTypes.number,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  odds: PropTypes.array,
  pages: PropTypes.number
};

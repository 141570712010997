import { fetchPicksBegin,
  fetchPicksSuccess,
  fetchPicksError } from '../actions';
import CONFIG from '../config/config';
import api from '../config/api';
import { alertError, alertSuccess } from '../actions/alerts';

export const fetchPicks = async (dispatch, currentPage, limit, status = 'unknown', sportId = -1, userEmail='') => {
  try {
    dispatch(fetchPicksBegin());
    const { data } = await api.get(`${CONFIG.api_url}/picks?page=${currentPage}&limit=${limit}&status=${status}&sportId=${sportId}&userEmail=${userEmail}`);
    data.status = status;
    data.limit = limit;
    dispatch(fetchPicksSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchPicksError(error));
  }
}

export const regradePick =  async (dispatch, id) => {
  try {
    const { data } = await api.patch(`${CONFIG.api_url}/picks/${id}`, {Status: null, resultApproved: 1});
    alertSuccess(data.message)
  } catch (error) {
    alertError(error)
    console.log(error)
  }
}
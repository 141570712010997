import React, { Component } from "react";
import { Button, Form, Col, FormGroup, FormControl, ControlLabel, DropdownButton, MenuItem } from "react-bootstrap";
import PropTypes from 'prop-types';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError, alertSuccess } from '../../../components/alerts';
import "./style.css";

class EditResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: {},
      finishRequiredFields: ['HomeScore', 'AwayScore', 'BinaryScore', 'Final'],
      otherRequiredFileds: ['Final']
    };
  }

  componentDidMount = async () => {
    try {
      const { id } = this.props.match.params;
      const { data } = await api.get(`${CONFIG.api_url}/results/${id}`);
      this.setState({ result: data.result });
    } catch (e) {
      alertError('Error while loading result');
      console.log(e.response.data);  //eslint-disable-line no-console
    }
  }

  getResultFieldsList = () => {
    const list = [];
    const fields = ['HomeScore', 'AwayScore', 'BinaryScore', 'Final', 'FinalType'];
    const { result } = this.state;

    for (const field in fields) {
      let required = '';

      // if (requiredFields.includes(fields[field])) {
      //   required = <span className="red">*</span>
      // }

      if (fields[field] === 'Final') {
        list.push(
          <FormGroup controlId={fields[field]} key={field}>
            <Col sm={4} componentClass={ControlLabel}> {fields[field]} {required}</Col>
            <Col sm={4}>
              <DropdownButton title={`${result[fields[field]]}`} className="edit-result-dropdown" >
                <MenuItem eventKey="1" onClick={this.handleSelectFinal}>true</MenuItem>
                <MenuItem eventKey="2" onClick={this.handleSelectFinal}>false</MenuItem>
              </DropdownButton>
            </Col>
          </FormGroup>);
      } else if (fields[field] === 'FinalType') {
        list.push(
          <FormGroup controlId={fields[field]} key={field}>
            <Col sm={4} componentClass={ControlLabel}> {fields[field]} {required}</Col>
            <Col sm={4}>
              <DropdownButton title={`${result[fields[field]]}`} className="edit-result-dropdown" >
                <MenuItem eventKey="1" onClick={this.handleSelectFinalType}>Finished</MenuItem>
                <MenuItem eventKey="2" onClick={this.handleSelectFinalType}>NotFinished</MenuItem>
                <MenuItem eventKey="3" onClick={this.handleSelectFinalType}>Canceled</MenuItem>
              </DropdownButton>
            </Col>
          </FormGroup>);
      } else {
        list.push(
          <FormGroup controlId={fields[field]} key={field}>
            <Col sm={4} componentClass={ControlLabel}> {fields[field]} {required}</Col>
            <Col sm={4}>
              <FormControl type="text" value={result[fields[field]]} onChange={this.handleChangeResultField} />
            </Col>
          </FormGroup>);
      }
    }

    return list;
  }

  handleSelectFinal = (event) => {
    const result = Object.assign({}, this.state.result);
    result['Final'] = event.target.innerText;
    this.setState({ result });
  }

  handleSelectFinalType = (event) => {
    const result = Object.assign({}, this.state.result);
    result['FinalType'] = event.target.innerText;
    this.setState({ result });
  }

  handleChangeResultField = (event) => {
    const result = Object.assign({}, this.state.result);
    result[event.target.id] = event.target.value;
    this.setState({ result });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { result, finishRequiredFields, otherRequiredFileds } = this.state;
      if (result['FinalType'] == 'Finished') {
        for (const field in finishRequiredFields) {
          if (!result[finishRequiredFields[field]] && result[finishRequiredFields[field]]!== 0) {
            return alertError(`${finishRequiredFields[field]} field is required!`);
          }
        }
      } else if (result['FinalType'] == 'NotFinished') {
        result['HomeScore'] = null
        result['AwayScore'] = null
        result['BinaryScore'] = null
        result['Final'] = false
      }
      

      const { data } = await api.patch(`${CONFIG.api_url}/results/${result.id}`, result);

      if (data.success) {
        alertSuccess('Result saved succesfully!');
      } else if (data.error) {
        alertError(data.error);
      }

      const { history } = this.props;
      history.push('/results')

      return true;
    } catch (e) {
      return alertError(e.response.data.error || e);
    }
  }

  handleCancel = async () => {
    const { history } = this.props;
    const { location: { search } } = this.props;
    const page = search.split('?cancel=')
    if (page.length > 0 && page[1] !== '') {
      history.push(page[1])  
    } else {
      history.push('/results')
    }
    
  }

  render() {
    const resultFields = this.getResultFieldsList();

    return (
      <Form horizontal onSubmit={this.handleSubmit}>
        {resultFields}
        <Col smOffset={4} sm={4}>
          <Button className="save-button" bsStyle="success" bsSize="large" type="submit"> Save </Button>
          <Button bsStyle="danger" bsSize="large" type="submit" onClick={this.handleCancel}> Cancel </Button>
        </Col>
      </Form>
    );
  }
}

export default EditResult;

EditResult.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
};

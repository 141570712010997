import React, { Component } from "react";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import PropTypes from 'prop-types';

import { connect } from '../../store/context';
import "./style.css";


class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: true
    };
  }

  handleLogout = () => {
    localStorage.clear();
    const { history } = this.props;
    history.push('/login')
  }

  handleNavigateOdds = () => {
    const { history } = this.props;
    history.push('/odds')
  }

  handleNavigateSports = () => {
    const { history } = this.props;
    history.push('/sports')
  }

  handleNavigateMatches = () => {
    const { history } = this.props;
    history.push('/matches')
  }

  handleNavigatePayments = () => {
    const { history } = this.props;
    history.push('/payments')
  }

  handleNavigateSocials = () => {
    const { history } = this.props;
    history.push('/socials')
  }

  handleNavigate = (e) => {
    const page = e.target.innerText;
    const { history } = this.props;

    switch (page) {
      case 'Matches':
        history.push('/matches');
        break;
      case 'Odds':
        history.push('/odds');
        break;
      case 'Sports':
        history.push('/sports');
        break;
      case 'Payments':
        history.push('/payments');
        break;
      case 'Users':
        history.push('/users');
        break;
      case 'Results':
        history.push('/results');
        break;
      case 'Socials':
        history.push('/socials');
        break;
      case 'Picks':
        history.push('/picks');
        break;
      case 'Subscriptions':
        history.push('/subscriptions')
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <Navbar fluid collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            SportsTrader Admin Panel
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>

        <Navbar.Collapse>
          <Nav>
            <NavItem onClick={this.handleNavigate}>Matches</NavItem>
            <NavItem onClick={this.handleNavigate}>Picks</NavItem>
            <NavItem onClick={this.handleNavigate}>Odds</NavItem>
            <NavItem onClick={this.handleNavigate}>Sports</NavItem>
            <NavItem onClick={this.handleNavigate}>Subscriptions</NavItem>
            <NavItem onClick={this.handleNavigate}>Payments</NavItem>
            <NavItem onClick={this.handleNavigate}>Users</NavItem>
            <NavItem onClick={this.handleNavigate}>Results</NavItem>
            <NavItem onClick={this.handleNavigate}>Socials</NavItem>
          </Nav>
          <Nav pullRight>
            {this.state.isAuthenticated ? <NavItem onClick={this.handleLogout}>Logout</NavItem> : <NavItem href="/login">Login</NavItem>}
          </Nav>

        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const select = (state) => {
  const pages = state.getIn(['odds', 'pages'], 0);
  const currentPage = state.getIn(['odds', 'currentPage'], 0);
  const limit = state.getIn(['odds', 'limit'], 0);

  return { pages, currentPage, limit };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(Header);

Header.propTypes = {
  history: PropTypes.object
};

import subscriptions from "../pages/subscriptions";

export const FETCH_ODDS_BEGIN   = 'FETCH_ODDS_BEGIN';
export const FETCH_ODDS_SUCCESS = 'FETCH_ODDS_SUCCESS';
export const FETCH_ODDS_FAILURE = 'FETCH_ODDS_FAILURE';
export const SEARCH_ODDS_BEGIN   = 'SEARCH_ODDS_BEGIN';
export const SEARCH_ODDS_SUCCESS = 'SEARCH_ODDS_SUCCESS';
export const SEARCH_ODDS_FAILURE = 'SEARCH_ODDS_FAILURE';
export const CHANGE_SPORT_FILTER_SUCCESS = 'CHANGE_SPORT_FILTER_SUCCESS';
export const FETCH_MATCHES_BEGIN   = 'FETCH_MATCHES_BEGIN';
export const FETCH_MATCHES_SUCCESS = 'FETCH_MATCHES_SUCCESS';
export const FETCH_MATCHES_FAILURE = 'FETCH_MATCHES_FAILURE';
export const FETCH_USERS_BEGIN   = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_RESULTS_BEGIN   = 'FETCH_RESULTS_BEGIN';
export const FETCH_RESULTS_SUCCESS = 'FETCH_RESULTS_SUCCESS';
export const FETCH_RESULTS_FAILURE = 'FETCH_RESULTS_FAILURE';
export const FETCH_SPORTS_BEGIN   = 'FETCH_SPORTS_BEGIN';
export const FETCH_SPORTS_SUCCESS = 'FETCH_SPORTS_SUCCESS';
export const FETCH_SPORTS_FAILURE = 'FETCH_SPORTS_FAILURE';
export const FETCH_PICKS_BEGIN   = 'FETCH_PICKS_BEGIN';
export const FETCH_PICKS_SUCCESS = 'FETCH_PICKS_SUCCESS';
export const FETCH_PICKS_FAILURE = 'FETCH_PICKS_FAILURE';
export const FETCH_SUBSCRIPTIONS_SUCCESS = 'FETCH_SUBSCRIPTIONS_SUCCESS'

export const fetchOddsBegin = () => ({
  type: FETCH_ODDS_BEGIN
});

export const fetchOddsSuccess = (odds) => ({
  type: FETCH_ODDS_SUCCESS,
  data: { odds }
});

export const fetchOddsError = (error) => ({
  type: FETCH_ODDS_FAILURE,
  data: { error }
});

export const searchOddsBegin = () => ({
  type: SEARCH_ODDS_BEGIN
});

export const searchOddsSuccess = (odds) => ({
  type: SEARCH_ODDS_SUCCESS,
  data: { odds }
});

export const searchOddsError = (error) => ({
  type: SEARCH_ODDS_FAILURE,
  data: { error }
});

export const changeSportFilterSuccess = (id) => ({
  type: CHANGE_SPORT_FILTER_SUCCESS,
  data: { id }
})

export const fetchMatchesBegin = () => ({
  type: FETCH_MATCHES_BEGIN
});

export const fetchMatchesSuccess = (matches) => ({
  type: FETCH_MATCHES_SUCCESS,
  data: { matches }
});

export const fetchMatchesError = (error) => ({
  type: FETCH_MATCHES_FAILURE,
  data: { error }
});

export const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});

export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  data: { users }
});

export const fetchUsersError = (error) => ({
  type: FETCH_USERS_FAILURE,
  data: { error }
});

export const fetchResultsBegin = () => ({
  type: FETCH_RESULTS_BEGIN
});

export const fetchResultsSuccess = (results) => ({
  type: FETCH_RESULTS_SUCCESS,
  data: { results }
});

export const fetchResultsError = (error) => ({
  type: FETCH_RESULTS_FAILURE,
  data: { error }
});

export const fetchSportsBegin = () => ({
  type: FETCH_SPORTS_BEGIN
});

export const fetchSportsSuccess = (sports) => ({
  type: FETCH_SPORTS_SUCCESS,
  data: { sports }
});

export const fetchSportsError = (error) => ({
  type: FETCH_SPORTS_FAILURE,
  data: { error }
});

export const fetchPicksBegin = () => ({
  type: FETCH_PICKS_BEGIN
});

export const fetchPicksSuccess = (picks) => ({
  type: FETCH_PICKS_SUCCESS,
  data: { picks }
});

export const fetchPicksError = (error) => ({
  type: FETCH_PICKS_FAILURE,
  data: { error }
});

export const fetchSubscriptionsSuccess = (subscriptions) => ({
  type: FETCH_SUBSCRIPTIONS_SUCCESS,
  data: { subscriptions }
})
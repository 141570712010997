import React, { Component } from "react";
import { Panel, Col, Row, DropdownButton, Glyphicon, FormControl, Label, MenuItem } from "react-bootstrap";
import PropTypes from 'prop-types';
import { changeSportFilterSuccess } from '../../actions';
import { fetchOdds } from '../../api/odds';
import { alertError } from '../../components/alerts';
import { connect } from '../../store/context';
import CONFIG from '../../config/config';
import api from '../../config/api';
import "./style.css";


class FilterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      sportsList: null,
      selectedSport: '',
      selectedSportId: null
    }
  }

  componentDidMount = async () => {
    try {
      const { data } = await api.get(`${CONFIG.api_url}/sports`);

      if (data && data.sports) {
        this.setState({sportsList: data.sports});
      }
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  handleSearchChange = (event) => {
    this.setState({ search: event.target.value });
    this.handleSearchOdds(event.target.value);
  }

  handleSearchOdds = async (search) => {
    try {
      const { limit, sportId } = this.props;
      const currentPage = 1;
      const { dispatch } = this.props;
      fetchOdds(dispatch, currentPage, limit, search, sportId);
    } catch (e) {
      alertError(e.response.data.error || e);
    }
  }

  getSportsDropdown = () => {
    const { sportsList, selectedSportId } = this.state;
    const result = [];
    let evk = 1;
    result.push(
      <MenuItem eventKey={evk} key="All" onClick={this.handleSelectSport} active={!selectedSportId}> All Sports </MenuItem>
    )
    result.push(
      <MenuItem divider key="divider" />
    )

    if (sportsList && sportsList.length > 0) {
      sportsList.forEach((sport) => {
        evk += 1;
        result.push(
          <MenuItem eventKey={evk} key={sport.Name} id={sport.id} onClick={this.handleSelectSport} active={selectedSportId === sport.id}> {sport.Name.toUpperCase()}  ({sport.oddsCount})</MenuItem>
        )
      })
    }

    return result;
  }

  handleSelectSport = (event) => {
    try {
      const { dispatch, limit } = this.props;
      dispatch(changeSportFilterSuccess(event.target.id));
      const { search } = this.state;
      fetchOdds(dispatch, 1, limit, search, event.target.id);
      this.setState({selectedSport: event.target.text, selectedSportId: parseInt(event.target.id)})
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
  }

  render() {
    const sportsDropdownItems = this.getSportsDropdown();
    const { selectedSport } = this.state;

    return (
      <Panel className="filter-panel">
        <Panel.Heading>
          <Row className="show-grid">
            <Col md={4}>
              <Label className="filter-panel-label">Sport Filter: </Label>
              <DropdownButton title={selectedSport || 'All Sports'} id="sports-filter">
                {sportsDropdownItems}
              </DropdownButton>
            </Col>
            <Col md={4} />
            <Col md={4}>
              <Col xs={10} md={10}>
                <FormControl type="text" placeholder="Search by Team" value={this.state.search} onChange={this.handleSearchChange} onKeyDown={this.handleKeyPress} />
              </Col>
              <Col md={2}>
                <Glyphicon glyph="search" className="filter-search-icon" />
              </Col>
            </Col>
          </Row>
        </Panel.Heading>
      </Panel>
    );
  }
}

const select = (state) => {
  const limit = state.getIn(['odds', 'limit'], 0);
  const searchValue = state.getIn(['odds', 'searchValue'], '');
  const sportId = state.getIn(['odds', 'sportId'], '');

  return { limit, searchValue, sportId };
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(select, mapDispatchToProps)(FilterPanel);

FilterPanel.propTypes = {
  dispatch: PropTypes.func,
  limit: PropTypes.number
};

import React, { Component } from "react";
import { Button, Form, Col, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import PropTypes from 'prop-types';
import { v4 as uuid} from 'uuid';
import CONFIG from '../../../config/config';
import api from '../../../config/api';
import { alertError, alertSuccess } from '../../../components/alerts';
import "./style.css";


class NewOdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      odd: {},
      requiredFields: ['OddID', 'EventID', 'SiteID', 'MoneyLineHome', 'MoneyLineAway', 'OddType']
    };
  }

  componentDidMount = () => {
    const { id } = this.props.match.params;
    const odd = Object.assign({}, this.state.odd);
    odd.EventID = id;
    odd.OddID = uuid();
    this.setState({odd})
  }

  getOddFieldsList = () => {
    const list = [];
    const fields = ['OddID', 'EventID', 'SiteID', 'MoneyLineHome', 'MoneyLineAway', 'DrawLine', 'PointSpreadHome', 'PointSpreadAway', 'PointSpreadHomeLine', 'PointSpreadAwayLine', 'TotalNumber', 'OverLine', 'UnderLine', 'OddType', 'Participant'];
    const { odd, requiredFields } = this.state;

    for (const field in fields) {
      let required = '';

      if (requiredFields.includes(fields[field])) {
        required = <span className="red">*</span>
      }

      list.push(
        <FormGroup controlId={fields[field]} key={fields[field]}>
          <Col sm={4} componentClass={ControlLabel}> {fields[field]} {required}</Col>
          <Col sm={4}>
            <FormControl type="text" value={odd[fields[field]] || ''} onChange={this.handleChangeOddField} />
          </Col>
        </FormGroup>);
    }

    return list;
  }

  handleChangeOddField = (event) => {
    const odd = Object.assign({}, this.state.odd);
    odd[event.target.id] = event.target.value;
    this.setState({ odd });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { odd, requiredFields } = this.state;

      for (const field in requiredFields) {
        if (!odd[requiredFields[field]]) {
          return alertError(`${requiredFields[field]} field is required!`);
        }
      }

      const { data } = await api.post(`${CONFIG.api_url}/odds/`, odd);

      if (data.success) {
        alertSuccess('Odd created succesfully!');
        const { history } = this.props;
        const { id } = this.props.match.params;
        history.push(`/matches/${id}/odds`);
      } else if (data.error) {
        alertError(data.error);
      }

      return true;
    } catch (e) {
      return alertError(e.response.data.error || e);
    }
  }

  handleCancel = async () => {
    const { history } = this.props;
    history.push('/matches')
  }

  render() {
    const oddFields = this.getOddFieldsList();
    return (
      <>
        <Form horizontal onSubmit={this.handleSubmit}>
          {oddFields}
          <Col smOffset={4} sm={4}>
            <Button className="save-button" bsStyle="success" bsSize="large" type="submit" > Save </Button>
            <Button bsStyle="danger" bsSize="large" type="submit" onClick={this.handleCancel} > Cancel </Button>
          </Col>
        </Form>
      </>
    );
  }
}

export default NewOdd;

NewOdd.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

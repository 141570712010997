import { fetchResultsBegin,
  fetchResultsSuccess,
  fetchResultsError } from '../actions';
import CONFIG from '../config/config';
import api from '../config/api';


export const fetchResults = async (dispatch, currentPage, limit, type = 'unknown', sportId = -1) => {
  try {
    dispatch(fetchResultsBegin());
    const { data } = await api.get(`${CONFIG.api_url}/results?page=${currentPage}&limit=${limit}&type=${type}&sportId=${sportId}`);
    data.role = type;
    data.limit = limit;
    dispatch(fetchResultsSuccess(data));
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    dispatch(fetchResultsError(error));
  }
}
